import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useManageBillingHistory } from "../../../hooks/useManageBillingHistory";

export default function BillingHistory() {
  const [stripeID, setStripeID] = useState("");
  const [billingHistory, setBillingHistory] = useState([]);

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    console.log("user", user);
    if (user.meta_data.find((meta) => meta.meta_key === "stripe_cus_id")) {
      setStripeID(
        user.meta_data.find((meta) => meta.meta_key === "stripe_cus_id")
          .meta_value
      );
      console.log(
        "stripeID",
        user.meta_data.find((meta) => meta.meta_key === "stripe_cus_id")
          .meta_value
      );
    }
  }, [user]);

  useEffect(() => {
    getBillingHistory();
  }, [stripeID]);

  const getBillingHistory = () => {
    const APIURL = `/api/stripe/payment-customer/${stripeID}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        console.log("res", res);
        console.log("payments", res.data.payment.data);
        setBillingHistory(res.data.payment.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [ListComponent] = useManageBillingHistory();

  return (
    <div>
      <Helmet>
        <title>Billing History - BIZZLL Dashboard</title>
      </Helmet>

      <ListComponent
        emptyMessage="No Purchases Made"
        btnText="Create New Restaurant"
        listItems={billingHistory}
      />
    </div>
  );
}
