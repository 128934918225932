import { getCookie } from "./manageCookies";

//** Important Constants */

// User Token
export const USER_TOKEN = getCookie("ubt");

// Main FE URL
export const FE_URL = process.env.REACT_APP_FE_URL || "http://localhost:3000";

// Dashboard URL
export const DASHBOARD_URL =
  process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3005";

// Main BE URL
export const BE_URL =
  process.env.REACT_APP_BE_API_URL || "http://localhost:5000";

// Specify main app domain
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN || "localhost";

// Specify Stripe key
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

// Captcha Key
export const GOOGLE_RECAPTCHA_SITEKEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY;

//** Global Display Constants */

// For displaying date on table items
export const TABLE_DATE_FORMAT = "MMM. DD, YYYY";

//** Form Constants */
export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm A";

//** Theme constants */
export const PURPLE_COLOR = "#8620CE";
export const RED_COLOR = "$red";
export const GREEN_COLOR = "#05704F";
export const BLUE_COLOR = "#458DD2";
