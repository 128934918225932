//** Import Modules */
import React, { useEffect } from "react";
import { Divider, Layout } from "antd";
import HeaderUserBar from "../../common/HeaderUserBar/HeaderUserBar";
import { Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//** Import Components */
import MainSettings from "./pages/MainSettings";
import BillingHistory from "./pages/BillingHistory";
import ManageOrdersUser from "./pages/ManageOrdersUser";
import ManageAppointmentsUser from "./pages/ManageAppointmentsUser";
import ManageEventsUser from "./pages/ManageEventsUser";
import MembershipDetails from "./pages/MembershipDetails";
import MyBusinesses from "./pages/MyBusinesses/MyBusinesses";
import Chats from "./pages/Chat/Chats";
import { getBusinessList } from "../../features/reducers/business";
import UserAvatar from "../../common/UserAvatar";
import DashboardMenu from "../../common/DashboardMenu";
import BurgerMenu from "./BurgerMenu";
import ManageAdsUser from "./pages/ManageAds/ManageAdsUser";

//** Import Menu Item */
import { dashboardMenu } from "./data/settingsMenuItems";
import ZoomSettings from "./pages/ZoomSettings/ZoomSettings";
import { Helmet } from "react-helmet";

//** Separate layout components */
const { Header, Content, Footer } = Layout;

export default function UserSettings() {
  const baseURL = "/account";

  // Get User Info
  const user = useSelector((state) => state.user.user);

  // Get business list
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessList());
  }, [dispatch]);

  return (
    <Layout className="layout">
      <Helmet>
        <title>User Settings - BIZZLL Dashboard</title>
      </Helmet>

      <Header id="user-settings-header">
        <div className="logo" />

        <HeaderUserBar />
      </Header>
      <Content id="user-settings">
        <div className="content-header">
          <UserAvatar user={user} size={100} />

          <div className="user-details">
            {user.user_fname} {user.user_lname}
            <span>
              Member Since:{" "}
              {moment(new Date(user && user.user_registered)).format(
                "MMMM DD, YYYY"
              )}
            </span>
          </div>
        </div>
        <div className="site-layout-content">
          <DashboardMenu
            baseURL={baseURL}
            dashboardMenu={dashboardMenu}
            mode="horizontal"
            className="dashboardMenu"
          />

          <BurgerMenu
            baseURL={baseURL}
            dashboardMenu={dashboardMenu}
            mode="vertical"
            className="burgerMenu"
          />

          <Divider />

          <Routes>
            {/* Main Settings */}
            <Route path="/" element={<MainSettings user={user} />} exact />

            {/* Purchases History */}
            <Route
              path="/billing-history"
              element={<BillingHistory user={user} />}
            />

            {/* User Orders */}
            <Route
              path="/user_orders/*"
              element={<ManageOrdersUser user={user} />}
            />

            {/* User Appointments */}
            <Route
              path="/user_appointments/*"
              element={<ManageAppointmentsUser user={user} />}
            />

            {/* User Orders */}
            <Route
              path="/user_events/*"
              element={<ManageEventsUser user={user} />}
            />

            {/* My Current Membership */}
            <Route
              path="/membership"
              element={<MembershipDetails user={user} />}
            />

            {/* My Business List */}
            <Route
              path="/my-businesses/*"
              element={<MyBusinesses user={user} />}
            />

            {/* My Chats List */}
            <Route
              path="/my-chats/*"
              element={<Chats user={user} type="user" idBusiness={null} />}
            />

            {/* My Chats List */}
            <Route
              path="/advertisements/*"
              element={<ManageAdsUser user={user} />}
            />

            {/* My Zoom Settings */}
            {/* <Route
              path="/zoom-settings/*"
              element={<ZoomSettings user={user} />}
            /> */}
          </Routes>
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Bizzll © 2024
      </Footer>
    </Layout>
  );
}
