import React from "react";
import { useNavigate } from "react-router";
import { Tabs } from "antd";
import ContentPageTitle from "../../../../common/ContentPageTitle";

import BusinessPendingApproval from "./BusinessPendingApproval";
import ApprovedBusiness from "./ApprovedBusiness";
import BusinessPendingChanges from "./BusinessPendingChanges";
import BusinessCanceled from "./BusinessCanceled";

export default function BusinessManagement(props) {
  const { user } = props;

  const navigate = useNavigate();

  const handleTabChange = (key) => {
    if (key === "1") {
      navigate("/bizzll-management/business-management");
    } else if (key === "2") {
      navigate("/bizzll-management/business-management");
    }
  };

  return (
    <>
      <div className="content-box">
        <ContentPageTitle title="Manage Businesses" icon="briefcase" />

        <Tabs
          defaultActiveKey="1"
          type="card"
          size="default"
          onChange={handleTabChange}
        >
          <Tabs.TabPane tab="Pending Approval" key="1">
            <BusinessPendingApproval user={user} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Pending Changes" key="2">
            <BusinessPendingChanges user={user} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approved" key="3">
            <ApprovedBusiness user={user} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Canceled" key="4">
            <BusinessCanceled user={user} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
}
