import React, { useState } from "react";
import { Modal, Button } from "antd";

const ConfirmAction = ({ children, onConfirm, title, content }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    onConfirm();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal
        title={title}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>{content}</p>
      </Modal>
    </>
  );
};

export default ConfirmAction;

// import ConfirmAction from "../../../../utils/ConfirmAction";
