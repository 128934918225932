import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import bizzllLogo from "../../images/bizzll-logo-beta.png";
import bizzllLogo from "../../../images/bizzll-logo-beta.png";

import { Button, message, Alert, Spin } from "antd";

import axiosGeneral from "../../../utils/axioGenetal";

export default function AccountVerification() {
  const location = useLocation();
  const [token, setToken] = useState("");

  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = location.pathname;
    const tokenFromUrl = searchParams.slice(8);
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      verifyUser(tokenFromUrl);
    }
  }, [location]);

  const verifyUser = async (token) => {
    try {
      await axiosGeneral()
        .get(
          `${process.env.REACT_APP_BE_API_URL}/api/users/verifyUser/${token}`
        )
        .then(() => {
          let config = {
            content: "User verified correctly",
            style: {
              background: "none",
            },
          };
          message.success(config);
          setLoading(false);
        });
    } catch (error) {
      let config = {
        content: error.response.data.message
          ? error.response.data.message
          : "ERROR",
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
    }
  };

  const handleClick = () => {
    navigate(`/`);
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "700px",
        margin: "75px auto",
      }}
    >
      <img
        style={{
          width: "300px",
        }}
        src={bizzllLogo}
        alt="Bizzll"
      />
      {loading && (
        <Spin tip="Verifying user...">
          <Alert
            message="Alert message title"
            description="Please wait while we verify your user."
            type="info"
          />
        </Spin>
      )}
      {!loading && (
        <div
          style={{
            width: "100%",
          }}
        >
          <h1>User Verified</h1>
          <Button onClick={() => handleClick()} type="primary">
            {" "}
            Go to login{" "}
          </Button>
        </div>
      )}
    </div>
  );
}
