//** Import Modules */
import React, { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function UploadMedia(props) {
  const {
    setMedia,
    label,
    multiple,
    maxCount,
    aspectRatio,
    description,
    form,
    fieldName,
  } = props;

  const [defaultFileList, setDefaultFileList] = useState([]);
  console.log(defaultFileList);

  useEffect(() => {
    const imageUrl =
      form.getFieldValue(fieldName) !== undefined
        ? form.getFieldValue(fieldName)
        : [];

    if (multiple && imageUrl.split) {
      const defaultFileListVar = [];

      imageUrl.split(",").map((el, index) => {
        return defaultFileListVar.push({
          uid: index,
          name: el.split("/")[4],
          status: "done",
          url: el,
          response: el,
        });
      });

      setDefaultFileList(defaultFileListVar);
    } else {
      if (imageUrl && imageUrl.split)
        setDefaultFileList([
          {
            uid: "1",
            name: imageUrl.split("/")[4],
            status: "done",
            url: imageUrl,
            response: imageUrl,
          },
        ]);
    }
  }, [fieldName, form, multiple]);

  const handleOnChange = async ({ fileList }) => {
    setDefaultFileList(fileList);

    console.log(fileList);

    if (multiple) {
      const newFileList = fileList.map((file) =>
        file.originFileObj ? file.originFileObj : file
      );

      setMedia(newFileList);
    } else {
      setMedia(fileList[0] ? fileList[0].originFileObj : "");
    }
  };

  return (
    <div className="upload-media-field">
      <div className="item-label">
        <label>{label}</label>
      </div>

      <div className="media-uploader">
        <div className="image-description">{description}</div>

        <ImgCrop /*rotate*/ rotationSlider aspect={aspectRatio}>
          <Upload.Dragger
            name="business_logo"
            listType={multiple ? "picture-card" : "picture"}
            onChange={handleOnChange}
            fileList={defaultFileList}
            multiple={multiple}
            maxCount={maxCount}
            beforeUpload={false}
          >
            <p className="ant-upload-drag-icon">
              <FontAwesomeIcon icon={faCloudArrowUp} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </ImgCrop>
      </div>
    </div>
  );
}
