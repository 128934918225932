import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useManageEvents } from "../../../../hooks/useManageEvents";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getEventsList } from "../../../../features/reducers/events";
import EventSettings from "./EventSettings";
import { Alert } from "antd";
import EventsAnalytics from "../ManageAnalytics/EventsAnalytics";

export default function ManageEvents(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const eventsList = useSelector((state) => {
    return state.events.eventsList;
  });

  useEffect(() => {
    dispatch(getEventsList(business.id));
  }, [business.id, dispatch]);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    ownerID: business.id,
    dispatchAction: getEventsList(business.id),
    deleteItemAPI: "/api/event/delete",
    itemType: "event",
    modalData: {
      title: "Create New Event",
      fieldLabel: "Event Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageEvents(hookData);

  return (
    <div id="events-page">
      <ContentPageTitle title="My Events" icon="users" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an event listing."
                  btnText="Create New Event"
                  listItems={eventsList}
                />

                <CreateItemButton btnText="Create New Event" />
              </>
            }
            exact
          />

          <Route path="/:id" element={<EventSettings />} exact />

          <Route path="/:id/insights" element={<EventsAnalytics />} />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
