import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BusinessSettings from "./BusinessSettings";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { message } from "antd";

//** Import Components */
import { useManageBusinessValidate } from "../../../../hooks/useManageBusinessValidate";

export default function BusinessPendingChanges(props) {
  const { user } = props;

  const [business, setBusiness] = useState([]);

  useEffect(() => {
    handleGetBusinessWaitingValidation();
  }, []);

  const handleGetBusinessWaitingValidation = async () => {
    try {
      await axiosWithAuth()
        .get(
          `${process.env.REACT_APP_BE_API_URL}/api/business/getBusinessesByStatus/pendingchanges`
        )
        .then((response) => {
          setBusiness(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [ListComponent] = useManageBusinessValidate();

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ListComponent
                emptyMessage="Seems like there’s no business waiting for changes."
                listItems={business}
              />
            </>
          }
          exact
        />

        <Route
          path="/:id"
          element={
            <BusinessSettings
              handleGetBusinessWaitingValidation={
                handleGetBusinessWaitingValidation
              }
            />
          }
          exact
        />
      </Routes>
    </>
  );
}
