import React from "react";
import ListBackBtn from "../../components/ListBackBtn";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AnalyticsBoxCounter from "../../../../common/AnalyticsBoxCounter";
import AgeGroups from "./DemographicComponents/AgeGroups";
import GenderGroups from "./DemographicComponents/GenderGroups";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function EventsAnalytics() {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Attendees",
        data: labels.map(() => 0),
        borderColor: "#8620ce",
        backgroundColor: "#8620ce",
      },
      {
        label: "Views",
        data: labels.map(() => 0),
        borderColor: "$red",
        backgroundColor: "$red",
      },
      {
        label: "Earnings",
        data: labels.map(() => 0),
        borderColor: "#05704f",
        backgroundColor: "#05704f",
      },
    ],
  };

  return (
    <>
      <ListBackBtn backURL="/business/events" btnTxt="Back To Events List" />

      <div className="analytics-container">
        <div className="main-stats">
          <AnalyticsBoxCounter title="Attendees" stat="0" color="purple" />
          <AnalyticsBoxCounter title="Views" stat="0" color="red" />
          <AnalyticsBoxCounter title="Earnings" stat="$0" color="green" />
        </div>

        <div className="graph">
          <Line data={data} height={120} />
        </div>

        <div className="demographics">
          <h3>Demographics</h3>

          <AgeGroups />
          <GenderGroups />
        </div>
      </div>
    </>
  );
}
