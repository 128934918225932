//** Import Components */
import { Table, Empty, Space, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const useManagementOrders = () => {
  const navigate = useNavigate();
  const dateFormat = TABLE_DATE_FORMAT;


  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <>{text.order_id}</>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.date_created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text) => (
        <>
          {
            text.order_status === 'Placed' &&
            <Tag icon={<ExclamationCircleOutlined />} color="error">
              {text && text.order_status}
            </Tag>
          }
          {
            text.order_status === 'Making' &&
            <Tag icon={<SyncOutlined spin />} color="processing">
              {text && text.order_status}
            </Tag>
          }
          {
            (text.order_status === 'On Its Way' || text.order_status === 'Ready For Pickup' ) &&
            <Tag icon={<ClockCircleOutlined />} color="warning">
              {text && text.order_status}
            </Tag>
          }
          {
            (text.order_status === 'Delivered' || text.order_status === 'Picked Up') &&
            <Tag icon={<CheckCircleOutlined />} color="success">
              {text && text.order_status}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <button
            onClick={() => {
              navigate(`${text.id}`);
            }}
          >
            View
          </button>
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};