import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  Divider,
  Select,
  Button,
  Checkbox,
  InputNumber,
  Tooltip,
  TimePicker,
  Spin,
} from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";

export default function ServiceInfo(props) {
  const { id, business } = props;

  // Used to build the form
  const [form] = Form.useForm();

  const [isFreeTicket, setIsFreeTicket] = useState(false);
  const [service, setService] = useState();

  useEffect(() => {
    getServiceData();
  }, [id]);

  const getServiceData = async () => {
    const response = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/products/${id}`
    );

    const rules = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/product/${id}`
    );

    setService({ ...response.data, availabilityRules: [...rules.data] });
  };

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(service);

  const priceText = <span>This price is for a block of time</span>;
  const options = ["Show", "Hide", "Center"];
  const [arrow, setArrow] = useState("Show");
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const onFinish = async (values) => {
    values.meta_service_duration_time =
      values.meta_service_duration_time.format("HH:mm");

    // Set price 0 if we have free selected, or just undefined
    if (
      values.meta_service_price === undefined ||
      values.meta_service_price === ""
    ) {
      values.meta_service_price = 0;
    }

    submitForm(values, `/api/products/update/${service.id}`);
  };

  useEffect(() => {
    const checkboxFree = form.getFieldValue("meta_service_free");

    if (checkboxFree === 1 || checkboxFree === "1") {
      form.setFieldsValue({
        meta_service_free: true,
      });
      setIsFreeTicket(true);
    } else if (checkboxFree === 0 || checkboxFree === "0") {
      form.setFieldsValue({
        meta_service_free: false,
      });
      setIsFreeTicket(false);
    }
  }, [loadingForm, form]);

  useEffect(() => {
    if (service !== undefined) {
      setFieldData();
      const checkboxFree = form.getFieldValue("meta_service_free");
      setIsFreeTicket(checkboxFree);
    }
  }, [service]);

  const onFreeEventChecked = (e) => {
    setIsFreeTicket(e.target.checked);

    if (e.target.checked) {
      form.setFieldsValue({
        meta_service_price: 0,
        isEventFree: true,
      });
    } else {
      form.setFieldsValue({
        meta_service_price: 1,
        isEventFree: false,
      });
    }
  };

  return (
    <>
      <ContentPageTitle title="Manage Service" icon="pen-to-square" />

      <Divider />
      {loadingForm ? (
        <LoadingForm />
      ) : (
        <div id="services">
          <Form
            form={form}
            layout="vertical"
            initialValues={fields}
            defaultValue
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            disabled={isSubmitting}
          >
            <div className="form-row">
              <div className="form-column-left">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input service's name",
                    },
                  ]}
                >
                  <Input placeholder="Service Name" />
                </Form.Item>

                <Form.Item
                  name="meta_service_description"
                  rules={[
                    {
                      required: true,
                      message: "Please input service's description",
                    },
                  ]}
                >
                  <TextArea placeholder="Service Description, Terms and Conditions of use of Service" />
                </Form.Item>
              </div>

              <div className="form-column-right">
                <Form.Item
                  name="meta_service_logo"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image!",
                    },
                  ]}
                >
                  <UploadMedia
                    setMedia={(file) =>
                      form.setFieldsValue({
                        meta_service_logo: file,
                      })
                    }
                    maxCount={1}
                    label="Service Logo"
                    fieldName="meta_service_logo"
                    form={form}
                    description="400px * 400px Image - Max Size 10MB"
                  />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <div id="service_price_duration" className="form-row">
              {business.connectAcc.payouts_enabled && (
                <div className="service_price_duration_center">
                  <Tooltip
                    placement="bottom"
                    title={priceText}
                    arrow={mergedArrow}
                  >
                    <Form.Item
                      label="Price"
                      name="meta_service_price"
                      rules={[
                        {
                          required: !isFreeTicket,
                          message: "Please input service's price",
                        },
                      ]}
                    >
                      <InputNumber
                        addonBefore="$"
                        placeholder="1.00"
                        min={0}
                        disabled={isFreeTicket}
                      />
                    </Form.Item>
                  </Tooltip>

                  <Form.Item
                    name="meta_service_free"
                    label="Free service?"
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={onFreeEventChecked}
                      checked={isFreeTicket}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="service_price_duration_center">
                <Form.Item
                  label="Duration"
                  name="meta_service_duration_time"
                  rules={[
                    {
                      required: true,
                      message: "Please input service's duration",
                    },
                  ]}
                >
                  <TimePicker format={"HH:mm"} showNow={false} />
                </Form.Item>
              </div>
            </div>

            <div id="appointments">
              <div className="min-time-appointments">
                <label>Min time to make the appointment</label>
                <Form.Item
                  name="meta_service_min_to_make_appointment"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input the minimum time to make the appointment",
                    },
                  ]}
                >
                  <InputNumber placeholder="1" min={0} />
                </Form.Item>
                <Form.Item
                  name="meta_service_min_to_make_appointment_period"
                  rules={[
                    {
                      required: true,
                      message: "Please select a time period",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 120 }}
                    placeholder="Select"
                    options={[
                      { value: "days", label: "days" },
                      { value: "hours", label: "hours" },
                    ]}
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="ant-btn ant-btn-primary"
                loading={isSubmitting}
              >
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}
