import { Select } from "antd";
import React from "react";

const { Option } = Select;

export default function DateRangeChooser({ onChange }) {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <div className="date-range">
      <Select
        defaultValue="cur-week"
        style={{ width: 200 }}
        onChange={handleChange}
      >
        <Option value="cur-week">This Week</Option>
        <Option value="last-week">Last Week</Option>
        <Option value="cur-month">This Month</Option>
        <Option value="last-month">Last Month</Option>
      </Select>
    </div>
  );
}
