//** Import Components */
import { useState, useEffect } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Form, Input, Modal, Button, Divider, Table, Empty, Space } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";

import {
  validateMaxLength,
  validateEmail,
  validateNumbers,
  validateLength,
  validateAddress,
} from "../utils/validations";
import { useDashboardFormData } from "../hooks/useDashboardFormData";
import { dashboardGetCities } from "../routes/BusinessDashboard/data/dashboardGetListData";
import ContentFormSubtitle from "../common/ContentFormSubtitle";
import SelectDropdown from "../common/FormInputs/SelectDropdown";

export const useManageCreateBusinessItems = ({
  itemType,
  ownerID,
  modalData,
  dispatchAction,
  deleteItemAPI,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    fields,
    setFieldData,
    isSubmitting,
    // submitForm,
    // onFinishFailed,
    loadingForm,
  ] = useDashboardFormData();

  // Used to get dropdown menu data
  const [cities, setCities] = useState([]);

  useEffect(() => {
    dashboardGetCities(setCities);
  }, []);

  //* Function to delete a list item
  const deleteListItem = (APIURL) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      axiosWithAuth()
        .delete(APIURL)
        .then((res) => {
          dispatch(dispatchAction);
          console.log(res.data, APIURL);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //* functions to handle modal
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModalForm = () => {
    modalForm.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    submitForm(values, `/api/business/create`, dispatchAction);
  };

  //* Submit the form
  const submitForm = async (formData, APIendpoint, dispatchFunction) => {
    // setIsSubmitting(true);
    let formattedData = {
      meta_data: [],
    };

    await Promise.all(
      Object.keys(formData).map(async (key) => {
        if (key.includes("meta")) {
          // Initialize the obj. Remove the "meta_" from string
          let metaObj = {
            meta_key: key.replace("meta_", ""),
            meta_value: formData[key] === undefined ? "" : formData[key],
          };

          // Adjust the date values
          if (key.includes("date")) {
            metaObj.meta_value = moment(metaObj.meta_value).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else if (key.includes("open_time") || key.includes("close_time")) {
            metaObj.meta_value = moment(metaObj.meta_value).format("HH:mm");
          }

          return (formattedData = {
            ...formattedData,
            meta_data: [...formattedData["meta_data"], metaObj],
          });
        } else {
          return (formattedData = {
            ...formattedData,
            [key]: formData[key],
          });
        }
      })
    );

    axiosWithAuth()
      .post(APIendpoint, formattedData)
      .then(() => {
        const modal = Modal.success({
          title: "Settings Saved",
          content: `Settings were saved successfully`,
        });

        if (dispatchFunction && dispatchFunction !== undefined) {
          dispatch(dispatchFunction);
        }

        closeModal();

        setTimeout(() => {
          modal.destroy();
        }, 5000);

        // setIsSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response);

        const modal = Modal.error({
          title: "Error Saving Business",
          content: err.message,
        });

        setTimeout(() => {
          modal.destroy();
        }, 5000);

        // setIsSubmitting(false);
      });
  };

  //* Component that holds the modal
  const modalComponent = (
    <Modal
      open={openModal}
      title={modalData.title}
      onCancel={closeModal}
      width="80%"
      footer={[
        <Button key="back" onClick={closeModal}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          onClick={submitModalForm}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={modalForm}
        layout="vertical"
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        onFinish={(values) => {
          values.owner_id = ownerID;
          onFinish(values);
        }}
        disabled={isSubmitting}
      >
        <div className="form-row">
          <Form.Item
            label="Business Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your business name!",
              },
              { validator: (_, value) => validateMaxLength(_, value, 40) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="meta_business_phone"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
              { validator: validateNumbers },
              { validator: (_, value) => validateLength(_, value, 10) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="meta_business_email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              { validator: validateEmail },
              { validator: (_, value) => validateMaxLength(_, value, 40) },
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item
          label="FEI/EIN Number"
          name="meta_business_fei_ein_number"
          rules={[
            {
              required: true,
              message: "Please input FEI/EIN number",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Divider />

        <ContentFormSubtitle title="Business Address" />

        <div className="form-row">
          <Form.Item
            label="Address"
            name="meta_business_address"
            rules={[
              {
                required: true,
                message: "Please input your business address!",
              },
              { validator: validateAddress },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Address 2"
            name="meta_business_address_2"
            tooltip="i.e. Suite # 123, Unit 123"
            rules={[{ validator: validateAddress }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="City"
            name="meta_business_city"
            rules={[
              {
                required: true,
                message: "Please choose a city!",
              },
            ]}
          >
            <SelectDropdown
              showSearch={true}
              options={
                cities && cities.length
                  ? cities.map((data) => {
                      return {
                        key: data.name,
                        value: data.name,
                      };
                    })
                  : []
              }
            />
          </Form.Item>

          <Form.Item
            label="State"
            name="meta_business_state"
            rules={[
              {
                required: true,
                message: "Please choose a state!",
              },
            ]}
          >
            <SelectDropdown
              showSearch={true}
              options={[{ key: "Florida", value: "FL" }]}
            />
          </Form.Item>

          <Form.Item
            label="ZIP Code"
            name="meta_business_zip"
            rules={[
              {
                required: true,
                message: "Please input your business ZIP code!",
              },
              { validator: validateNumbers },
              { validator: (_, value) => validateLength(_, value, 5) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Country"
            name="meta_business_country"
            rules={[
              {
                required: true,
                message: "Please choose a country!",
              },
            ]}
          >
            <SelectDropdown
              showSearch={true}
              options={[{ key: "United States", value: "USA" }]}
            />
          </Form.Item>
        </div>

        <Divider />

        <ContentFormSubtitle title="Business Registered Agent" />

        <Form.Item
          label="Agent Name"
          name="meta_business_agent_name"
          rules={[
            {
              required: true,
              message: "Please input a name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Agent Title"
          name="meta_business_agent_title"
          rules={[
            {
              required: true,
              message: "Please input a title",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Agent Address"
          name="meta_agent_address"
          rules={[
            {
              required: true,
              message: "Please input an address",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {isSubmitting ? "Saving" : "Save Settings"}
          </Button>
        </Form.Item> */}
      </Form>
    </Modal>
  );

  const CreateItemButton = ({ btnText }) => {
    return (
      <div className="create-item">
        <Button type="primary" onClick={showModal}>
          {btnText}
        </Button>
      </div>
    );
  };

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <Link to={`${text.id}`}>{text.name}</Link>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Last Modified",
      key: "modified",
      render: (text) => (
        <>{moment(new Date(text && text.modified)).format(dateFormat)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <button
            onClick={() => {
              const APIURL = `${deleteItemAPI}/${text.id}`;
              deleteListItem(APIURL, text.id);
            }}
          >
            Delete
          </button>
          <button
            onClick={() => {
              navigate(`${text.id}`);
            }}
          >
            Manage
          </button>
          <button
            onClick={() => {
              navigate(`${text.id}/insights`);
            }}
          >
            Analytics
          </button>
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [modalComponent, CreateItemButton, ListComponent];
};
