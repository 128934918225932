//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setEventsList } from "../../reducers/events";
import {
  requestGetEventsList,
} from "../requests/events";


export function* handleGetEventsList({ businessID }) {
  try {
    const response = yield call(requestGetEventsList, businessID);
    const { data } = response;

    yield put(setEventsList(data));
  } catch (err) {
    console.log(err.response);
  }
}
