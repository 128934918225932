import React, { useEffect } from "react";
import { Form, Divider, Select, Button, Table } from "antd";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../../../../common/constants";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";

export default function OrderInfo(props) {
    const { order } = props;

    const dateFormat = TABLE_DATE_FORMAT;

    // Used to build the form
    const [form] = Form.useForm();

    const [
        fields,
        setFieldData,
        isSubmitting,
        submitForm,
        onFinishFailed,
        loadingForm,
    ] = useDashboardFormData(order);

    const onFinish = async (values) => {
        console.log('valuessss', values)
        // submitForm(
        //     values,
        //     `/api/products/update/${order.order_id}`,
        //     getRestaurant(order.order_id)
        // );
    };

    useEffect(() => {
        if (order.order_id !== undefined) {
            setFieldData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const columns = [
        {
            title: "Product",
            key: "product_name",
            render: (text) => <>{text.product_name} x {text.product_quantity}</>,
        },
        {
            title: "Total",
            key: "total",
            render: (text) => (
                <>{text.product_price * text.product_quantity} </>
            ),
        },
    ];

    return (
        <>
            <ContentPageTitle title="Manage Menu Item" icon="pen-to-square" />
            <Divider />
            <p>Order #{order.order_id} was placed on {moment(new Date(order.date_created)).format(dateFormat)} and is currently {order.order_status}</p>

            <Table columns={columns} dataSource={order.order_items} />
            
            <p><b>Subtotal: </b> {order.order_total}</p>
            <p><b>Total: </b> {order.order_total}</p>

            <Divider />

            {loadingForm ? (
                <LoadingForm />
            ) : (
                <div id="restaurants">
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={fields}
                        defaultValue
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        disabled={isSubmitting}
                    >
                        <Form.Item
                            name="meta_order_description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input order's description"
                                }
                            ]}
                        >
                            <TextArea placeholder="Description" />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="meta_order_status"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select order's status"
                                }
                            ]}
                        >
                            <Select
                                style={{ width: 120 }}
                                placeholder="Select"
                                options={[
                                    { value: 'Placed', label: 'Placed' },
                                    { value: 'Making', label: 'Making' },
                                    { value: 'On Its Way', label: 'On Its Way' },
                                    { value: 'Delivered', label: 'Delivered' },
                                ]}
                            />
                        </Form.Item>

                        <Divider />

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className='ant-btn ant-btn-primary' name="button_submit" loading={isSubmitting}>
                                {isSubmitting ? "Saving" : "Save Settings"}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </>
    );
}
