// import React, { useState, useEffect } from "react";
// import axiosWithAuth from "../../../../utils/axiosWithAuth";
// import { Button } from "antd";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";

// export default function JitsiMeet(props) {
//   const { appointment } = props;
//   const [meetingUrl, setMeetingUrl] = useState("");
//   const [isMeetingTime, setIsMeetingTime] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log("appointment", appointment);
//     if (appointment && appointment.meta_data) {
//       const jitsiMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "meet_jitsi"
//       );

//       const startTimeMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "event_starttime"
//       );
//       const endTimeMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "event_endtime"
//       );
//       const eventDateMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "event_date"
//       );

//       if (startTimeMeta && endTimeMeta && eventDateMeta) {
//         const startTimeValue = startTimeMeta.meta_value;
//         const endTimeValue = endTimeMeta.meta_value;
//         const eventDateValue = eventDateMeta.meta_value.split(" ")[0];

//         const eventStartDateTime = moment(
//           `${eventDateValue} ${startTimeValue}`,
//           "YYYY-MM-DD HH:mm:ss"
//         );
//         const eventEndDateTime = moment(
//           `${eventDateValue} ${endTimeValue}`,
//           "YYYY-MM-DD HH:mm:ss"
//         );
//         const now = moment();

//         if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
//           const minutesToStart = moment
//             .duration(eventStartDateTime.diff(now))
//             .asMinutes();
//           const minutesToEnd = moment
//             .duration(eventEndDateTime.diff(now))
//             .asMinutes();

//           if (minutesToStart <= 30 && minutesToEnd >= 0) {
//             setIsMeetingTime(true);
//             if (jitsiMeta) {
//               setMeetingUrl(jitsiMeta.meta_value);
//             }
//           }
//         }
//       }
//     }
//   }, [appointment]);

//   const handleButtonClick = () => {
//     if (meetingUrl) {
//       navigate(`/jitsi-meet/${appointment.id}`);
//     }
//   };

//   if (!appointment) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button onClick={handleButtonClick} disabled={!isMeetingTime}>
//         Join Meeting
//       </button>
//     </div>
//   );
// }

//ESTE FUNCIONABAAAAAAAAAAAAAAAAAAAAAAAAAAA/////////////////////////////////////////////////////////

// import React, { useEffect, useState, useRef } from "react";
// import moment from "moment";

// function base64UrlEncode(str) {
//   return btoa(unescape(encodeURIComponent(str)))
//     .replace(/\+/g, "-")
//     .replace(/\//g, "_")
//     .replace(/=+$/, "");
// }

// function generateJWT(payload, secret) {
//   const header = {
//     alg: "HS256",
//     typ: "JWT",
//   };

//   const encodedHeader = base64UrlEncode(JSON.stringify(header));
//   const encodedPayload = base64UrlEncode(JSON.stringify(payload));

//   const token = `${encodedHeader}.${encodedPayload}`;

//   // Simple signature placeholder
//   const signature = btoa(secret)
//     .replace(/\+/g, "-")
//     .replace(/\//g, "_")
//     .replace(/=+$/, "");

//   return `${token}.${signature}`;
// }

// export default function JitsiMeet(props) {
//   const { appointment } = props;
//   const [isMeetingTime, setIsMeetingTime] = useState(false);
//   const [jwtToken, setJwtToken] = useState("");
//   const roomName = `room-${appointment?.id || "default-room"}`;
//   const jitsiContainerRef = useRef(null);

//   // Load the Jitsi Meet script dynamically
//   useEffect(() => {
//     const loadJitsiScript = () => {
//       return new Promise((resolve, reject) => {
//         if (window.JitsiMeetExternalAPI) {
//           resolve();
//         } else {
//           const script = document.createElement("script");
//           script.src = "https://meet.jit.si/external_api.js";
//           script.async = true;
//           script.onload = () => resolve();
//           script.onerror = () =>
//             reject(new Error("Failed to load Jitsi Meet script"));
//           document.body.appendChild(script);
//         }
//       });
//     };

//     loadJitsiScript().catch((error) => console.error(error));

//     return () => {
//       const script = document.querySelector(
//         "script[src='https://meet.jit.si/external_api.js']"
//       );
//       if (script) {
//         document.body.removeChild(script);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     if (appointment && appointment.meta_data) {
//       const startTimeMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "event_starttime"
//       );
//       const endTimeMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "event_endtime"
//       );
//       const eventDateMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "event_date"
//       );

//       if (startTimeMeta && endTimeMeta && eventDateMeta) {
//         const startTimeValue = startTimeMeta.meta_value;
//         const endTimeValue = endTimeMeta.meta_value;
//         const eventDateValue = eventDateMeta.meta_value.split(" ")[0];

//         const eventStartDateTime = moment(
//           `${eventDateValue} ${startTimeValue}`,
//           "YYYY-MM-DD HH:mm:ss"
//         );
//         const eventEndDateTime = moment(
//           `${eventDateValue} ${endTimeValue}`,
//           "YYYY-MM-DD HH:mm:ss"
//         );
//         const now = moment();

//         if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
//           const minutesToStart = moment
//             .duration(eventStartDateTime.diff(now))
//             .asMinutes();
//           const minutesToEnd = moment
//             .duration(eventEndDateTime.diff(now))
//             .asMinutes();

//           if (minutesToStart <= 30 && minutesToEnd >= 0) {
//             setIsMeetingTime(true);
//             const payload = {
//               context: {
//                 user: {
//                   name: "User Name",
//                   email: "user@example.com",
//                   id: "user-id",
//                   moderator: true,
//                 },
//               },
//               room: roomName,
//               exp: Math.floor(Date.now() / 1000) + 3600,
//             };
//             const token = generateJWT(payload, "your_app_secret");
//             setJwtToken(token);
//           }
//         }
//       }
//     }
//   }, [appointment, roomName]);

//   useEffect(() => {
//     if (jwtToken && isMeetingTime && window.JitsiMeetExternalAPI) {
//       const domain = "9c92-89-2-7-38.ngrok-free.app";
//       const options = {
//         roomName: roomName,
//         parentNode: jitsiContainerRef.current,
//         jwt: jwtToken,
//         userInfo: {
//           displayName: "TuNombre",
//         },
//       };

//       const api = new window.JitsiMeetExternalAPI(domain, options);

//       api.addEventListener("videoConferenceJoined", () => {
//         console.log("Usuario ha entrado en la reunión");
//       });

//       return () => api.dispose();
//     } else {
//       console.error("JitsiMeetExternalAPI no está disponible en window.");
//     }
//   }, [jwtToken, isMeetingTime, roomName]);

//   if (!appointment) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button disabled={!isMeetingTime}>Join Meeting</button>
//       <div
//         ref={jitsiContainerRef}
//         style={{ height: "600px", width: "100%" }}
//       ></div>
//     </div>
//   );
// }

// name: user.user_fname + " " + user.user_lname,
// email: user.user_email,
// id: user.id,
// moderator: true,

import React, { useEffect, useState } from "react";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";

function base64UrlEncode(str) {
  return btoa(unescape(encodeURIComponent(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

function generateJWT(payload, secret) {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const encodedHeader = base64UrlEncode(JSON.stringify(header));
  const encodedPayload = base64UrlEncode(JSON.stringify(payload));

  const token = `${encodedHeader}.${encodedPayload}`;

  // Simple signature placeholder
  const signature = btoa(secret)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return `${token}.${signature}`;
}

export default function JitsiMeet(props) {
  const { appointment } = props;
  const [isMeetingTime, setIsMeetingTime] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [jwtTokenClient, setJwtTokenClient] = useState("");
  const roomName = `room-${appointment?.id || "default-room"}`;
  const user = useSelector((state) => state.user.user);

  // Obtener el dominio desde la variable de entorno
  const domain = process.env.REACT_APP_JITSI_DOMAIN;

  useEffect(() => {
    if (appointment && appointment.meta_data) {
      const startTimeMeta = appointment.meta_data.find(
        (meta) => meta.meta_key === "event_starttime"
      );
      const endTimeMeta = appointment.meta_data.find(
        (meta) => meta.meta_key === "event_endtime"
      );
      const eventDateMeta = appointment.meta_data.find(
        (meta) => meta.meta_key === "event_date"
      );

      if (startTimeMeta && endTimeMeta && eventDateMeta) {
        const startTimeValue = startTimeMeta.meta_value;
        const endTimeValue = endTimeMeta.meta_value;
        const eventDateValue = eventDateMeta.meta_value.split(" ")[0];

        const eventStartDateTime = moment(
          `${eventDateValue} ${startTimeValue}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const eventEndDateTime = moment(
          `${eventDateValue} ${endTimeValue}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const now = moment();

        if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
          const minutesToStart = moment
            .duration(eventStartDateTime.diff(now))
            .asMinutes();
          const minutesToEnd = moment
            .duration(eventEndDateTime.diff(now))
            .asMinutes();

          if (minutesToStart <= 30 && minutesToEnd >= 0) {
            setIsMeetingTime(true);
            getJWT();
          }
        }
      }
    }
  }, [appointment, roomName]);

  const handleJoinMeeting = () => {
    if (isMeetingTime && jwtToken) {
      // const meetingUrl = `https://${domain}/${roomName}?jwt=${jwtToken}`;
      const meetingUrl = `https://${domain}/room-4?jwt=${jwtToken}`;
      // Abrir la reunión en una nueva pestaña
      window.open(meetingUrl, "_blank");
    }
  };

  if (!appointment) {
    return <div>Loading...</div>;
  }

  const getJWT = async () => {
    const APIURL = `/api/jitsi-meet/accessAuth`;
    try {
      const res = await axiosWithAuth().post(APIURL, {
        // roomName: roomName,
        roomName: "room-4",
        user: user,
        eventData: appointment,
      });
      setJwtToken(res.data);
      console.log("res", res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button onClick={handleJoinMeeting} disabled={!isMeetingTime}>
        Join Meeting
      </button>
    </div>
  );
}
