import React, { useEffect, useState } from "react";
import { Calendar, DatePicker, Divider } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import moment from "moment";
import AppointmentsByDayTable from "./AppointmentsByDayTable";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function AppointmentsByMonth(props) {
  const { business } = props;

  const [listItems, setListItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    getAppointmentsByMonth(selectedDate);
  }, [selectedDate]);

  const getAppointmentsByMonth = (date) => {
    const year = date.year();
    const month = monthNames[date.month()];
    const business_id = business.id;

    const APIURL = `/api/appointments/list/monthly`;

    const payload = {
      year,
      month,
      business_id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        setListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const onChange = (value) => {
    setSelectedDate(value);
  };

  return (
    <div>
      <DatePicker
        onChange={onChange}
        picker="month"
        allowClear={false}
        defaultValue={selectedDate}
      />
      <Divider />
      <div className="appointment_table_container">
        <AppointmentsByDayTable
          appointmentByDay={listItems}
          handleRefresh={getAppointmentsByMonth}
        />
      </div>
    </div>
  );
}
