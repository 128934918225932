//** Import Modules */
import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { Route, Routes } from "react-router-dom";
import { useManagementCategory } from "../../../../hooks/useManagementCategory";
import CategoriesSettings from "./CategoriesSettings";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function CategoriesManagement(props) {
  const { user } = props;

  //* Get Categories
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    const APIURL = `/api/business/categories/list`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        const categoriesList = res.data.map((category) => {
          return {
            ...category,
            key: category.id,
          };
        });

        setCategories(categoriesList);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  //* Prepare Hook
  const hookData = {
    creatorID: user.id,
    setState: setCategories,
    itemType: "category",
    dispatchAction: getAllCategories,
    deleteItemAPI: "/api/business/category",
    modalData: {
      title: "Create New Category",
      fieldLabel: "Category Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManagementCategory(hookData);

  return (
    <div>
      <ContentPageTitle title="Categories Management" icon="list" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have any categories."
                  btnText="Create New category"
                  listItems={categories}
                />

                <CreateItemButton btnText="Create New Category" />
              </>
            }
            exact
          />

          <Route path="/:id" element={<CategoriesSettings />} exact />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
