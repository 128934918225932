//** Import Modules */
import React, { useState, useEffect } from 'react';
import { Menu, Icon, Drawer, Button } from 'antd';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BurgerMenu(props) {
    const { baseURL, dashboardMenu, mode } = props;

    const [visible, SetVisible] = useState(false);

    const [openKeys, setOpenKeys] = useState(["manage-earnings"]);
    
    // Get Router info
    const navigate = useNavigate();
    const location = useLocation();

    // Get User Info
    const user = useSelector((state) => state.user.user);
    const roleCapabilities = user.role ? user.role.capabilities : [];

    // Function to restrict items depending on capabilities
    const allowAccess = (capability) => {
        if (capability === undefined || roleCapabilities.includes(capability)) {
        return true;
        }

        return false;
    };

    // Build the items for the menu
    const menuItems = dashboardMenu.map((menuItem) => {
        const childrenItems = menuItem.items.map((subMenuItem) => {
        const allowItem = allowAccess(subMenuItem.capability);

        if (!allowItem) {
            return "";
        }

        return {
            label: subMenuItem.label,
            key: subMenuItem.slug,
            icon: <FontAwesomeIcon icon='arrow-right' />,
        };
        });

        const MenuIcon =
        menuItem.icon !== undefined ? (
            <FontAwesomeIcon icon={`fa-solid fa-${menuItem.icon}`} />
        ) : null;

        const allowItem = allowAccess(menuItem.capability);

        if (!allowItem) {
        return "";
        }

        return {
        label: menuItem.title,
        key: menuItem.key,
        children: childrenItems.length > 0 ? childrenItems : null,
        title: menuItem.title,
        icon: MenuIcon,
        };
    });

    const showDrawer = () => {
        SetVisible(true);
    };
    const onClose = () => {
        SetVisible(false);
    };

      // Checks which tab is open and selects it
    const curLocationSlug = location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
    );

    const curMenuItem = curLocationSlug !== "" ? curLocationSlug : "home";

    useEffect(() => {
        const getCurTabKey = dashboardMenu
        .map((menuItem) => {
            const childSelected = menuItem.items
            .map((subMenuItem) => {
                if (subMenuItem.slug === curLocationSlug) {
                return subMenuItem.parent;
                }

                return "";
            })
            .filter((item) => item !== "")[0];

            if (menuItem.key === curLocationSlug) {
            return menuItem.key;
            } else if (childSelected !== undefined) {
            return childSelected;
            }

            return "";
        })
        .filter((item) => item !== "");

        setOpenKeys(getCurTabKey);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Create click event for menu items
    const menuLink = (slug) => {
        if (slug === "home") {
        navigate(`${baseURL}/`);
        } else {
        navigate(`${baseURL}/${slug}`);
        }
    };

    return (
        <>
            <Button className="barsMenu" type="primary" onClick={showDrawer}>
                <FontAwesomeIcon icon='bars' />
            </Button>
            <Drawer
              title="Menu"
              placement="left"
              closable={true}
              onClose={onClose}
              open={visible}
              width={360}
            >
                <Menu
                    // theme='dark'
                    mode={mode}
                    defaultSelectedKeys={[curMenuItem]}
                    onClick={({ key }) => menuLink(key)}
                    items={menuItems}
                    className='dashboard-menu'
                    openKeys={openKeys}
                />
            </Drawer>
        </>
    );
}