import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import AppointmentsByDayTable from "./AppointmentsByDayTable";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function HomeDailyAppintments() {
  const business = useSelector((state) => {
    return state.business.business;
  });

  const [listItems, setListItems] = useState([]);
  const [appointmentByDay, setAppointmentByDay] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    getAppointmentsByMonth();
  }, [selectedDate, business]);

  useEffect(() => {
    const filteredAppointments = listItems.filter(
      (appointment) => moment(appointment.start_time).date() === selectedDate
    );
    setAppointmentByDay(filteredAppointments);
  }, [listItems]);

  const getAppointmentsByMonth = () => {
    const year = selectedDate.year();
    const month = monthNames[selectedDate.month()];
    const business_id = business.id;

    const APIURL = `/api/appointments/list/monthly`;

    const payload = {
      year,
      month,
      business_id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        setListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <div>
      <AppointmentsByDayTable
        appointmentByDay={appointmentByDay}
        handleRefresh={getAppointmentsByMonth}
      />
    </div>
  );
}
