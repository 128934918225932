import { Button, Input, Modal, Tag } from "antd";
import React, { useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";

export default function GroupInviteBtn({ group }) {
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [emailInput, setEmailInput] = useState("");

  const toggleModal = (state) => {
    setOpenModal(state);

    if (state === false) {
      resetModal();
    }
  };

  const resetModal = () => {
    setEmailList([]);
    setEmailInput("");
    setIsSubmitting(false);
    setError("");
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    if (emailList.length > 0) {
      const APIURL = `/api/group/inviteUser`;

      let updatedEmailList = [...emailList];
      updatedEmailList = updatedEmailList.map((email) => email.toLowerCase());

      axiosWithAuth()
        .post(APIURL, { emailId: updatedEmailList, groupId: group.id })
        .then(() => {
          resetModal();
          setOpenModal(false);

          Modal.success({
            title: "Invites Sent",
            content:
              "Invites sent successfully. The emails you have listed will receive an email with an invite link.",
          });
        })
        .catch((err) => {
          console.log(err.response);
          setError(err.response.data.message);
          setIsSubmitting(false);
        });
    } else {
      setError("You must add at least 1 email");
      setIsSubmitting(false);
    }
  };

  const handleInput = (e) => {
    setEmailInput(e.target.value);
  };

  const handleInputConfirm = () => {
    if (emailInput && emailList.indexOf(emailInput) === -1) {
      if (emailInput.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        let updatedEmailList = [...emailList];
        updatedEmailList.push(emailInput);

        setEmailList(updatedEmailList);
        setError("");
      } else {
        setError("Please enter a valid email address");
      }
    } else {
      setError("Email address already on the list");
    }

    setEmailInput("");
  };

  const handleRemoveEmail = (email) => {
    const updatedEmailList = emailList.filter((item) => email !== item);

    setEmailList(updatedEmailList);
  };

  return (
    <>
      <Button type='primary' onClick={() => toggleModal(true)}>
        Invite To Group
      </Button>

      <Modal
        open={openModal}
        className='invite-modal'
        title='Invite to the group'
        onCancel={() => toggleModal(false)}
        footer={[
          <Button
            key='back'
            disabled={isSubmitting}
            onClick={() => {
              setEmailList([]);
              toggleModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Send Invites
          </Button>,
        ]}
      >
        {/* <h3>
          Below, you can add multiple emails to send invites to. You may also
          search for a specific business, and an invite will be sent to the
          email on file for that business. NOTE: due to privacy reasons, if a
          user has set their business email as private, or there is no email on
          file, it will not show on the list.
        </h3> */}

        <h3>
          Below, you can add multiple emails to send invites to. They will
          receive an email with an invite link.
        </h3>

        <div className='row-group'>
          <Input
            type='text'
            placeholder='Add Email Address'
            onChange={handleInput}
            onPressEnter={handleInputConfirm}
            value={emailInput}
            disabled={isSubmitting}
          />

          <Button type='primary' onClick={handleInputConfirm}>
            +
          </Button>
        </div>

        {error && <p style={{ color: "red" }}>{error}</p>}

        {emailList.length > 0 && (
          <div className='email-list tags-list'>
            {emailList.map((email) => {
              return (
                <Tag
                  key={email}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleRemoveEmail(email);
                  }}
                >
                  {email}
                </Tag>
              );
            })}
          </div>
        )}
      </Modal>
    </>
  );
}
