import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Divider } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

//** Import Components */
import BusinessInfo from "../../../BusinessDashboard/pages/BusinessInfo";

export default function BusinessVerification(props) {
  const businessID = useParams();
  const [business, setBusiness] = useState();

  useEffect(() => {
    handleGetBusiness();
    console.log("BUSINESSSSSSSSSSSSSS", business);
    console.log("businessID", businessID);
  }, [businessID]);

  const handleGetBusiness = async () => {
    const response = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/business/${businessID.id}`
    );

    console.log("response", response);

    setBusiness(response.data);
  };

  return (
    <>
      <div className="warning-boxes">
        <Alert
          message="Verification Process"
          description={
            <>
              After submitting your business information, please note that your
              account will undergo a verification process by our administration
              team to ensure compliance with legal standards. As part of this
              process, the information you provide will be compared to the
              details published in the business registration records at
              <a href="https://www.sunbiz.org" target="_blank">
                {" "}
                www.sunbiz.org
              </a>
              . We appreciate your patience during this verification period.
              Once approved, you will gain full access to our platforms
              features.
            </>
          }
          type="warning"
        />
      </div>
      <Divider />
      {business !== undefined && <BusinessInfo business={business} />}
    </>
  );
}
