import { Alert, Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayoutOnboardingBtn from "./PayoutOnboardingBtn";
import { DASHBOARD_URL, STRIPE_KEY } from "../constants";
import LinkBankAccount from "./LinkBankAccount";
import ModifyPayoutSettings from "./ModifyPayoutSettings";

const stripePromise = loadStripe(STRIPE_KEY);

export default function PayoutTableDetails(props) {
  const { connectAcc, objData, type, baseURL } = props;

  console.log("objData", objData);

  const [disablePayout, setDisablePayout] = useState(true);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [requiresOnboarding, setRequiresOnboarding] = useState(false);
  const [requiresBankAcc, setRequiresBankAcc] = useState(false);
  const [missingData, setMissingData] = useState([]);

  useEffect(() => {
    if (connectAcc.requirements) {
      const missingInfoList = connectAcc.requirements.eventually_due;

      let missingItems = [];

      if (missingInfoList.includes("tos_acceptance.date")) {
        missingItems.push("Finish Payout Onboarding");
      }

      if (missingInfoList.includes("external_account")) {
        missingItems.push("Add a Bank Account");
      }

      setMissingData(missingItems);
      setDisablePayout(!connectAcc.transfers_enabled);
      setRequiresOnboarding(missingInfoList.includes("tos_acceptance.date"));
      setRequiresBankAcc(missingInfoList.includes("external_account"));
    }
  }, [connectAcc]);

  return (
    <>
      <div className='payout-table'>
        <div className='warning-boxes'>
          {missingData.length > 0 && (
            <Alert
              message='Payouts Disabled'
              description={
                <>
                  It seems you are missing some data before you can use payouts:
                  <ul>
                    {missingData.map((item) => {
                      return <li key={item}>{item}</li>;
                    })}
                  </ul>
                </>
              }
              type='error'
              showIcon
            />
          )}
        </div>

        {requiresOnboarding && (
          <div className='missing-data-btn'>
            <PayoutOnboardingBtn
              userID={objData.id}
              type={type}
              btnTxt='Resume Payout Onboarding'
              returnURL={`${DASHBOARD_URL}${baseURL}`}
              stripeAcc={connectAcc.id}
            />
          </div>
        )}

        {requiresBankAcc && (
          <div className='missing-data-btn' style={{ marginBottom: 20 }}>
            <Elements stripe={stripePromise}>
              <LinkBankAccount objData={objData} type={type} />
            </Elements>
          </div>
        )}

        <div className='payout-wrapper'>
          <div className='payout-balances'>
            <div className='this-week balance-wrapper'>
              <p>Pending Balance</p>
              <h3>${objData.connectAcc.pending[0].amount / 100}</h3>
            </div>

            <div className='current-balance balance-wrapper'>
              <p>Current Balance</p>
              <h3>${objData.connectAcc.available[0].amount / 100}</h3>
            </div>

            <div className='payout-btn'>
              <Button type='primary' disabled={disablePayout}>
                Transfer Payout
              </Button>

              <Elements stripe={stripePromise}>
                <ModifyPayoutSettings business={objData} baseURL={baseURL} />
              </Elements>
            </div>
          </div>

          <div className='current-sales'>
            <Tabs defaultActiveKey='1' type='card' size='default'>
              <Tabs.TabPane tab='Sales' key='1'>
                <h3>Recent Sales</h3>

                <h2>Currently you have no sales</h2>
              </Tabs.TabPane>
              <Tabs.TabPane tab='Transfers' key='2'>
                <h3>Bank Payout Transfers</h3>

                <h2>Currently you have no transfers</h2>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
