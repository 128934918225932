//** Import Modules */
import React, { useState } from "react";
import { Layout } from "antd";
import DashboardSidebar from "./components/DashboardSidebar";
import { Route, Routes } from "react-router";
import { useSelector } from "react-redux";

//** Import Components */
import HeaderUserBar from "../../common/HeaderUserBar/HeaderUserBar";
import Home from "./pages/Home";
import CitiesManagement from "./pages/CitiesManagement/CitiesManagement";
import CategoriesManagement from "./pages/CategoriesManagement/CategoriesManagement";
import BusinessManagement from "./pages/BusinessManagement/BusinessManagement";

//** Separate antd Layout Components */
const { Header, Content, Footer } = Layout;

export default function BizzllManagement() {
  // Setup the base url
  const baseURL = "/bizzll-management";

  // Setup States
  const [collapsed, setCollapsed] = useState(false);

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  console.log("user", user);

  return (
    <>
      <Layout hasSider>
        <DashboardSidebar
          baseURL={baseURL}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />

        <Layout
          className="dashboard-page-layout"
          style={{ marginLeft: collapsed ? 100 : 300 }}
        >
          <Header id="dashboard-header">
            <HeaderUserBar />
          </Header>

          <Content id="dashboard-content">
            <Routes>
              {/* Home Route */}
              <Route path="/" element={<Home />} />

              {/* Business Management Route */}
              <Route
                path="/business-management/*"
                element={<BusinessManagement user={user} />}
              />

              {/* Cities Management Route */}
              <Route
                path="/cities-management/*"
                element={<CitiesManagement user={user} />}
              />

              {/* Categories Management Route */}
              <Route
                path="/categories-management/*"
                element={<CategoriesManagement user={user} />}
              />
            </Routes>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Bizzll © 2024
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}
