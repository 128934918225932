import { Divider } from "antd";
import React from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import EventTicketsList from "./EventTicketsList";

export default function EventTickets(props) {
  const { event } = props;

  return (
    <div>
      <ContentPageTitle title='Event Tickets' icon='pen-to-square' />

      <Divider />

      <EventTicketsList event={event} />
    </div>
  );
}
