//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetEventsList(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/event/business/${businessID}`,
  });
}

// export function requestGetEventsTickets(eventID) {
//   return axiosWithAuth().request({
//     method: "get",
//     url: `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/getEventTickets/${eventID}`,
//   });
// }
