import React from "react";
import logo from "../images/bizzll-logo-beta.png";

export default function PageNotFound() {
  return (
    <>
      <div style={{
        width: '100%',
        maxWidth: '700px',
        margin: '20px auto'
      }}> 
        <img style={{
          width: '150px'
        }} src={logo} alt="" />
        <h1 style={{
          fontSize: '150px',
          marginBottom: '0',
          marginTop: '0'
        }}>Oops!</h1>
        <h2 style={{
          fontSize: '36px',
        }}>
          {/* We can't seem to find the page you're looking for. */}
          You've stumbled upon the elusive Error 404. Fear not, I'm here to guide you back. Ready to get back on track?
        </h2>
        <h3 style={{
          fontSize: '24px',
        }}>Error code: 404</h3>
      </div>
    </>
  );
}
