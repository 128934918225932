//** Import Components */
import { useState } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Form, Input, Modal, Button, message, Table, Empty, Space } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";

export const useManageBillingHistory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Payment ID",
      key: "payment_id",
      render: (text) => text.id,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment.unix(text.created).format(TABLE_DATE_FORMAT)}</>
      ),
    },
    // {
    //   title: "Last Modified",
    //   key: "modified",
    //   render: (text) => <>{console.log("TEXT", text)}</>,
    // },
    {
      title: "Receipt URL",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <a href={text.receipt_url} target="_blank" rel="noreferrer">
            View receipt
          </a>
          {/* <Button type="primary" href={text.receipt_url} target="_blank">
            View Receipt
          </Button> */}
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};
