import React, { useEffect, useState } from "react";
import { DatePicker, Divider } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import { useManagementAppointments } from "../../../../hooks/useManagementAppointments";
import { Routes, Route } from "react-router-dom";
import AppointmentsByWeekInfo from "./AppointmentsByWeekInfo";
import moment from "moment";
import dayjs from "dayjs";

export default function AppointmentsByWeek(props) {
  const { business } = props;

  const user = useSelector((state) => state.user.user);
  const [listItems, setListItems] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment());

  const weekFormat = "MM/DD/YY";

  const customWeekStartEndFormat = (value) =>
    `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
      .endOf("week")
      .format(weekFormat)}`;

  useEffect(() => {
    fetchAppointments(moment());
  }, [business.id]);

  const fetchAppointments = (date) => {
    const year = date.year();
    const weekNum = date.week();
    const business_id = business.id;

    const APIURL = `/api/appointments/list/weekly`;

    const payload = {
      year,
      weekNum,
      business_id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        setListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleDateChange = (value) => {
    setCurrentDate(value);
    fetchAppointments(value);
  };

  const hookData = {
    creatorID: user.id,
    itemType: "products",
    type: "restaurant",
  };

  const [ListComponent] = useManagementAppointments(hookData);

  return (
    <div id="orders-page">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <DatePicker
                onChange={handleDateChange}
                value={currentDate}
                format={customWeekStartEndFormat}
                picker="week"
              />
              <Divider />
              <ListComponent
                emptyMessage="Seems like you don't have an appointment listing."
                listItems={listItems}
              />
            </>
          }
          exact
        />
        <Route
          path="/:id"
          element={<AppointmentsByWeekInfo business={business} />}
          exact
        />
      </Routes>
    </div>
  );
}
