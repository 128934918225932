import React, { useEffect } from "react";
import ListBackBtn from "../../components/ListBackBtn";
import { Divider } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

//** Import Components */
import ContentPageTitle from "../../../../common/ContentPageTitle";

export default function AppointmentsByWeekInfo(props) {

    const { business } = props;

    useEffect(() => {
    
        const APIURL = `/api/products/business/${business.id}/service`;
    
        axiosWithAuth()
          .get(APIURL)
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
    
      }, [business.id]);

    return (
        <>
          <ListBackBtn backURL='/business/appointments' btnTxt='Back To Services List' />
            <ContentPageTitle title="Manage Appointment" icon="pen-to-square" />
          <Divider />
        </>
    );
}
