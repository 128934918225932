//** Import Modules */
import React, { useEffect } from "react";
import { Button, Form, Input, Divider, DatePicker, message } from "antd";
import moment from 'moment';

//** Import Components */
import { useDashboardFormData } from "../../../hooks/useDashboardFormData";
import SelectDropdown from "../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../common/ContentFormSubtitle";
import UploadMedia from "../../../common/FormInputs/UploadMedia";
import { getUser } from "../../../features/reducers/user";
import LoadingForm from "../../../common/LoadingForm";
import { validateName, validateMaxLength, validateEmail, validateLastName } from "../../../utils/validations";

export default function BusinessDescription(props) {
  const { user } = props;

  // Used to build the form
  const [form] = Form.useForm();

  const formRef = React.useRef();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(user);

  useEffect(() => {
    if (user.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const disabledDate = (current) => {
    const minDate = moment().subtract(100, 'years');
    const maxDate = moment().subtract(18, 'years');
    return current && (current < minDate || current > maxDate);
  };

  const handleDateChange = (date, dateString) => {
    const minDate = moment().subtract(100, 'years');
    const maxDate = moment().subtract(18, 'years');
    if (date.isBefore(minDate)) {
        message.error('You must be at least 18 years old to register');
        formRef.current.setFieldsValue({ birthDate: null });
    } else if (date.isAfter(maxDate)) {
        message.error('You must be less than 100 years old to register');
        formRef.current.setFieldsValue({ birthDate: null });
    }
  };

  return (
    <div>
      <div className="content-box">
        <ContentFormSubtitle title="User Account" />
        Here we will put important details, such as email, username, password,
        email
        <Divider />
        {loadingForm ? (
          <LoadingForm />
        ) : (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={fields}
            onFinishFailed={onFinishFailed}
            onFinish={async (values) => {
              submitForm(values, `/api/users/user/update`, getUser());
            }}
            disabled={isSubmitting}
          >
            <ContentFormSubtitle title="General Details" />

            <Form.Item 
              name="meta_user_logo" 
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please upload an image!",
                },
              ]}
            >
              <UploadMedia
                setMedia={(file) =>
                  form.setFieldsValue({
                    meta_user_logo: file,
                  })
                }
                maxCount={1}
                label="User Avatar"
                fieldName="meta_user_logo"
                form={form}
                description="400px * 400px Image - Max Size 10MB"
              />
            </Form.Item>

            <div className="form-row">
              <Form.Item
                label="First Name"
                name="user_fname"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                  { validator: validateName },
                  { validator: (_, value) => validateMaxLength(_, value, 20) }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="user_lname"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                  { validator: validateLastName },
                  { validator: (_, value) => validateMaxLength(_, value, 20) }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="user_email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  { validator: validateEmail },
                  { validator: (_, value) => validateMaxLength(_, value, 40) }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Gender"
                name="meta_gender"
                tooltip="We collect gender information for analytics purposes. See our privacy policy for more information."
                rules={[
                  {
                    required: true,
                    message: "Please choose a gender!",
                  },
                ]}
              >
                <SelectDropdown
                  showSearch={true}
                  options={[
                    { key: "Male", value: "Male" },
                    { key: "Female", value: "Female" },
                    { key: "Non-Binary", value: "Non-Binary" },
                    { key: "None", value: "Prefer not to respond" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Date of Birth"
                name="meta_date_of_birth"
                tooltip="We collect your age information for analytics purposes. See our privacy policy for more information."
                rules={[
                  {
                    required: true,
                    message: "Please input your date of birth!",
                  },
                ]}
              >
                <DatePicker format="MM/DD/YYYY" disabledDate={disabledDate} onChange={handleDateChange} />
              </Form.Item>
            </div>

            <Divider />

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
