import React from "react";
import OrderInfo from "./OrderInfo";
import ListBackBtn from "../../components/ListBackBtn";

export default function OrderSettings(props) {

  const {ordersList} = props;

  return (
    <>
      <ListBackBtn backURL='/business/orders' btnTxt='Back To Orders List' />
      <OrderInfo order={ordersList[0]} />
    </>
  );
}
