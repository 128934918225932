import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../common/ContentPageTitle";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import ManageAnalytics from "./ManageAnalytics/ManageAnalytics";
import moment from "moment";
import axiosWithAuth from "../../../utils/axiosWithAuth";
// import { FE_URL } from "../../common/constants";
import { FE_URL } from "../../../common/constants";
import HomeDailyAppintments from "./ManageAppointments/HomeDailyAppintments";
import HomeDailyEvents from "./ManageEvents/HomeDailyEvents";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function Home() {
  const business = useSelector((state) => {
    return state.business.business;
  });
  const [profileURL, setProfileURL] = useState("");

  useEffect(() => {
    if (business.meta_data && business.meta_data.length > 0) {
      const getProfileHandle = business.meta_data.filter(
        (b) => b.meta_key === "business_handle"
      )[0];

      if (getProfileHandle !== undefined) {
        const profileURL = `${FE_URL}/business/${getProfileHandle.meta_value}`;

        setProfileURL(profileURL);
      }
    }
  }, [business]);

  return (
    <div>
      {/* <ContentPageTitle title="Dashboard Home" icon="users" /> */}

      {/* <div className="content-box"> */}
      {profileURL && (
        <a
          href={profileURL}
          className="preview-btn"
          target="_blank"
          rel="noreferrer"
        >
          View My Profile
        </a>
      )}
      <ManageAnalytics business={business} />
      <Divider />
      <div className="content-box">
        <HomeDailyAppintments />
      </div>
      <Divider />
      <div className="content-box">
        <HomeDailyEvents business={business} />
      </div>
    </div>
  );
}
