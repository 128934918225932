// import React, { useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";

// const EmbeddedJitsiMeet = () => {
//   const { appointmentId } = useParams();
//   const jitsiContainerRef = useRef(null);

//   const user = useSelector((state) => state.user.user);

//   useEffect(() => {
//     if (window.JitsiMeetExternalAPI) {
//       const domain = "meet.jit.si";
//       const options = {
//         roomName: `Bizzll Room-${appointmentId}`,
//         parentNode: jitsiContainerRef.current,
//         width: "100%",
//         height: "100%",
//         userInfo: {
//           displayName: user.user_fname + " " + user.user_lname,
//         },
//         configOverwrite: {
//           disableInviteFunctions: true, // Deshabilitar todas las funciones de invitación
//           prejoinPageEnabled: false, // Desactiva la página de pre-unión
//         },
//         interfaceConfigOverwrite: {
//           TOOLBAR_BUTTONS: [
//             "microphone",
//             "camera",
//             "closedcaptions",
//             "desktop",
//             "fullscreen",
//             "fodeviceselection",
//             "hangup",
//             "profile",
//             "recording",
//             "livestreaming",
//             "etherpad",
//             "sharedvideo",
//             "settings",
//             "raisehand",
//             "videoquality",
//             "filmstrip",
//             "shortcuts",
//             "tileview",
//             "download",
//             "help",
//             "mute-everyone",
//             "security",
//           ],
//           SHOW_JITSI_WATERMARK: false,
//           HIDE_INVITE_MORE_HEADER: true,
//           SHOW_BRAND: false,
//           SHOW_APP_NAME: false,
//         },
//       };

//       const api = new window.JitsiMeetExternalAPI(domain, options);

//       // Opcional: Añadir más configuraciones de API si es necesario

//       return () => api.dispose();
//     } else {
//       console.error(
//         "JitsiMeetExternalAPI no está disponible. Asegúrate de que el script de Jitsi Meet se haya cargado correctamente."
//       );
//     }
//   }, [appointmentId]);

//   return (
//     <div ref={jitsiContainerRef} style={{ width: "100%", height: "100vh" }} />
//   );
// };

// export default EmbeddedJitsiMeet;

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function base64UrlEncode(str) {
  return btoa(unescape(encodeURIComponent(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

function generateJWT(payload, secret) {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const encodedHeader = base64UrlEncode(JSON.stringify(header));
  const encodedPayload = base64UrlEncode(JSON.stringify(payload));

  const token = `${encodedHeader}.${encodedPayload}`;

  // Simple signature placeholder
  const signature = btoa(secret)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return `${token}.${signature}`;
}

const EmbeddedJitsiMeet = () => {
  const { appointmentId } = useParams();
  const jitsiContainerRef = useRef(null);
  const [jwtToken, setJwtToken] = useState("");
  const [jitsiLoaded, setJitsiLoaded] = useState(false);

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    // Cargar el script de Jitsi Meet dinámicamente
    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.onload = () => setJitsiLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (jitsiLoaded) {
      const payload = {
        context: {
          user: {
            name: "User Name",
            email: "user@example.com",
            id: "user-id",
            moderator: true,
          },
        },
        room: `Bizzll Room-${appointmentId}`,
        exp: Math.floor(Date.now() / 1000) + 3600,
      };
      const token = generateJWT(payload, "your_app_secret");
      setJwtToken(token);
    }
  }, [jitsiLoaded, appointmentId]);

  useEffect(() => {
    if (jwtToken && window.JitsiMeetExternalAPI) {
      const domain = "https://4806-81-252-53-66.ngrok-free.app";
      const options = {
        roomName: `Bizzll Room-${appointmentId}`,
        parentNode: document.getElementById("jitsi-container"),
        jwt: jwtToken,
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);

      // api.addEventListener("videoConferenceJoined", () => {
      //   console.log("Usuario ha entrado en la reunión");
      // });

      return () => api.dispose();
    } else {
      console.error("JitsiMeetExternalAPI no está disponible en window.");
    }
  }, [jwtToken, appointmentId, jitsiLoaded]);

  return (
    <div id="jitsi-container" style={{ height: "600px", width: "100%" }}></div>
  );
};

export default EmbeddedJitsiMeet;
