import React, { useState, useEffect } from "react";
import { Form, Button, DatePicker, Modal, List } from "antd";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import ConfirmAction from "../../../../utils/ConfirmAction";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const DayOffAvailabilityRules = ({ id }) => {
  const [form] = Form.useForm();
  const [daysOff, setDaysOff] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [daysOffToDelete, setDaysOffToDelete] = useState([]);
  const [loading, setLoading] = useState(true); // Para mostrar un indicador de carga

  useEffect(() => {
    getDaysOffData();
  }, [id]);

  const getDaysOffData = async () => {
    setLoading(true); // Inicia el estado de carga
    try {
      const { data } = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/product/${id}`
      );

      // Filtramos las reglas que son de tipo "day_off"
      const dayOffRules = data
        .filter((rule) => rule.rule_type === "day_off")
        .map((rule) => ({
          id: rule.id,
          date: rule.rule_val_1, // Asumimos que la fecha está en rule_val_1
        }));

      setDaysOff(dayOffRules || []);
    } catch (error) {
      Modal.error({ title: "Error", content: "Failed to fetch day off data" });
    } finally {
      setLoading(false); // Termina el estado de carga
    }
  };

  const handleAddDayOff = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.format("YYYY-MM-DD");
      if (!daysOff.some((day) => day.date === formattedDate)) {
        setDaysOff([...daysOff, { id: null, date: formattedDate }]);
        setSelectedDate(null); // Resetea la fecha seleccionada
      } else {
        Modal.error({
          title: "Validation Error",
          content: "This day off has already been added.",
        });
      }
    } else {
      Modal.error({
        title: "Validation Error",
        content: "Please select a date before adding.",
      });
    }
  };

  const handleRemoveDayOff = (index) => {
    const removedDayOff = daysOff[index];
    if (removedDayOff.id) {
      setDaysOffToDelete((prev) => [...prev, removedDayOff]);
    }
    setDaysOff(daysOff.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const newDaysOff = [];
      const existingDaysOff = [];

      for (const day of daysOff) {
        const dayOffData = {
          rule_type: "day_off",
          rule_val_1: day.date,
          product_id: id,
        };

        if (day.id === null) {
          newDaysOff.push(dayOffData);
        } else {
          dayOffData.id = day.id;
          existingDaysOff.push(dayOffData);
        }
      }

      if (newDaysOff.length > 0) {
        await axiosWithAuth().post(
          `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/create`,
          newDaysOff
        );
      }

      if (daysOffToDelete.length > 0) {
        await axiosWithAuth().post(
          `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/delete`,
          daysOffToDelete
        );
      }

      Modal.success({
        title: "Success",
        content: "Days off were saved successfully",
      });

      getDaysOffData(); // Recarga los datos después de guardar
    } catch (error) {
      Modal.error({
        title: "Error",
        content: `Failed to save days off: ${error.message}`,
      });
    } finally {
      setIsSubmitting(false);
      setDaysOffToDelete([]);
    }
  };

  return (
    <>
      <ContentPageTitle title="Manage Day Off Availability" icon="calendar" />
      {loading ? (
        <LoadingForm />
      ) : (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <DatePicker
            format="YYYY-MM-DD"
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            placeholder="Select a day off"
            disabledDate={(current) =>
              current && current < moment().endOf("day")
            }
          />
          <Button
            type="dashed"
            onClick={handleAddDayOff}
            icon={<PlusOutlined />}
            style={{ marginLeft: "10px" }}
          >
            Add Day Off
          </Button>

          <List
            dataSource={daysOff}
            renderItem={(day, index) => (
              <List.Item>
                <span>{day.date}</span>
                <ConfirmAction
                  title="Confirm Deletion"
                  content="Are you sure you want to delete this day off?"
                  onConfirm={() => handleRemoveDayOff(index)}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </ConfirmAction>
              </List.Item>
            )}
          />

          <Form.Item style={{ marginTop: "20px" }}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default DayOffAvailabilityRules;
