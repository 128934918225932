const validateLetters = (_, value) => {
  if (!value || /^[a-zA-Z]*$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter only letters");
};

const validateMaxLength = (_, value, maxLength) => {
  if (!value || value.length <= maxLength) {
    return Promise.resolve();
  }
  return Promise.reject(`The value cannot exceed ${maxLength} characters`);
};

const validatePassword = (_, value) => {
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  if (strongRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
  );
};

const validateNumbers = (_, value) => {
  if (!value || /^[0-9]*$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter only numbers");
};

const validateName = (_, value) => {
  if (!value || /^[\p{L}\p{M}\p{N}\p{P}\s-]+$/u.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid name");
};

const validateLastName = (_, value) => {
  if (!value || /^[\p{L}\p{M}\p{N}\p{P}\s-]+$/u.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid last name");
};

const validateEmail = (_, value) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid email address");
};

const validateLength = (_, value, length) => {
  if (value && value.length === length) {
    return Promise.resolve();
  }
  return Promise.reject(`Please enter exactly ${length} characters`);
};

const validateAddress = (_, value) => {
  const addressRegex = /^[a-zA-Z0-9\s.,#-]*$/;
  if (addressRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid address");
};

const validateURL = (_, value) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  if (urlRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid URL");
};

const validateAlphanumericWithSymbols = (_, value) => {
  const alphanumericWithSymbolsRegex = /^[\p{L}\p{M}\p{N}\-_\.]+$/u;
  if (alphanumericWithSymbolsRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    "Please enter only letters, numbers, hyphen, underscore, dot, or tilde"
  );
};

export {
  validateLetters,
  validateMaxLength,
  validatePassword,
  validateNumbers,
  validateName,
  validateLastName,
  validateEmail,
  validateLength,
  validateAddress,
  validateURL,
  validateAlphanumericWithSymbols,
};
