import React from "react";
import { useSelector } from "react-redux";
import { useManagementAppointments } from "../../../../hooks/useManagementAppointments";

export default function AppointmentsByDayTable(props) {
  const { appointmentByDay, handleRefresh } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  // Custom hook to manage the listing
  const hookData = {
    creatorID: user.id,
    itemType: "products",
    type: "restaurant",
    handleRefresh: handleRefresh,
  };

  const [ListComponent] = useManagementAppointments(hookData);

  return (
    <ListComponent
      emptyMessage="Seems like you don't have an appointment listing."
      listItems={appointmentByDay}
    />
  );
}
