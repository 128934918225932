// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import axiosWithAuth from "../../../../utils/axiosWithAuth";

// export default function JitsiMeetUser(props) {
//   const { product, type } = props;
//   const [meetingUrl, setMeetingUrl] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchMeetingUrl = async () => {
//       try {
//         if (product) {
//           const jitsiMeta = product.meta_data.find(
//             (meta) => meta.meta_key === "meet_jitsi"
//           );
//           const startTimeMeta = product.meta_data.find(
//             (meta) =>
//               meta.meta_key ===
//               (type === "appointment"
//                 ? "appointment_starttime"
//                 : "event_starttime")
//           );
//           const endTimeMeta = product.meta_data.find(
//             (meta) =>
//               meta.meta_key ===
//               (type === "appointment" ? "appointment_endtime" : "event_endtime")
//           );
//           const eventDateMeta = product.meta_data.find(
//             (meta) =>
//               meta.meta_key ===
//               (type === "appointment" ? "appointment_date" : "event_date")
//           );

//           if (startTimeMeta && endTimeMeta && eventDateMeta) {
//             const startTimeValue = startTimeMeta.meta_value;
//             const endTimeValue = endTimeMeta.meta_value;
//             const eventDateValue = eventDateMeta.meta_value.split(" ")[0];

//             const eventStartDateTime = moment(
//               `${eventDateValue} ${startTimeValue}`,
//               "YYYY-MM-DD HH:mm:ss"
//             );
//             const eventEndDateTime = moment(
//               `${eventDateValue} ${endTimeValue}`,
//               "YYYY-MM-DD HH:mm:ss"
//             );
//             const now = moment();

//             if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
//               const minutesToStart = moment
//                 .duration(eventStartDateTime.diff(now))
//                 .asMinutes();
//               const minutesToEnd = moment
//                 .duration(eventEndDateTime.diff(now))
//                 .asMinutes();

//               if (jitsiMeta && minutesToStart <= 30 && minutesToEnd >= 0) {
//                 setMeetingUrl(jitsiMeta.meta_value);
//               }
//             } else {
//               console.log("Invalid startTime or endTime");
//             }
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching meeting URL:", error);
//       }
//     };

//     fetchMeetingUrl();
//   }, [product, type]);

//   const handleButtonClick = () => {
//     console.log("product", product);
//     if (meetingUrl) {
//       navigate(
//         `/jitsi-meet/${
//           type === "event" ? product.event_id : product.appintment_id
//         }`
//       );
//     }
//   };

//   if (!product) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button onClick={handleButtonClick} disabled={!meetingUrl}>
//         Join Meeting
//       </button>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";

export default function JitsiMeetUser(props) {
  const { product, type } = props;
  const [meetingUrl, setMeetingUrl] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const roomName = `room-${product?.id || "default-room"}`;
  const user = useSelector((state) => state.user.user);

  // Obtener el dominio desde la variable de entorno
  const domain = process.env.REACT_APP_JITSI_DOMAIN;

  useEffect(() => {
    const fetchMeetingData = async () => {
      try {
        if (product) {
          const jitsiMeta = product.meta_data.find(
            (meta) => meta.meta_key === "meet_jitsi"
          );
          const startTimeMeta = product.meta_data.find(
            (meta) =>
              meta.meta_key ===
              (type === "appointment"
                ? "appointment_starttime"
                : "event_starttime")
          );
          const endTimeMeta = product.meta_data.find(
            (meta) =>
              meta.meta_key ===
              (type === "appointment" ? "appointment_endtime" : "event_endtime")
          );
          const eventDateMeta = product.meta_data.find(
            (meta) =>
              meta.meta_key ===
              (type === "appointment" ? "appointment_date" : "event_date")
          );

          if (startTimeMeta && endTimeMeta && eventDateMeta) {
            const startTimeValue = startTimeMeta.meta_value;
            const endTimeValue = endTimeMeta.meta_value;
            const eventDateValue = eventDateMeta.meta_value.split(" ")[0];

            const eventStartDateTime = moment(
              `${eventDateValue} ${startTimeValue}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const eventEndDateTime = moment(
              `${eventDateValue} ${endTimeValue}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const now = moment();

            if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
              const minutesToStart = moment
                .duration(eventStartDateTime.diff(now))
                .asMinutes();
              const minutesToEnd = moment
                .duration(eventEndDateTime.diff(now))
                .asMinutes();

              // if (jitsiMeta && minutesToStart <= 30 && minutesToEnd >= 0) {
              setMeetingUrl(jitsiMeta.meta_value);

              // Solo generar el JWT cuando es necesario y una sola vez
              if (!jwtToken) {
                getJWT();
              }
              // }
            } else {
              console.log("Invalid startTime or endTime");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    fetchMeetingData();
  }, [product, type, jwtToken]); // Asegurarse de no regenerar el JWT innecesariamente

  const handleJoinMeeting = () => {
    if (meetingUrl && jwtToken) {
      // Configuraciones para deshabilitar funcionalidades no deseadas para los usuarios no moderadores
      const config = {
        disableInviteFunctions: true,
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        disableRemoteMute: true,
        disableKick: true,
        disableShare: true,
      };

      const configParams = Object.keys(config)
        .map((key) => `config.${key}=${config[key]}`)
        .join("&");

      // Generar la URL completa con las restricciones y el token JWT
      // const fullMeetingUrl = `https://${domain}/${roomName}?jwt=${jwtToken}&${configParams}`;
      const fullMeetingUrl = `https://${domain}/room-4?jwt=${jwtToken}&${configParams}`;

      // Abrir la URL en una nueva pestaña
      window.open(fullMeetingUrl, "_blank");
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const getJWT = async () => {
    const APIURL = `/api/jitsi-meet/accessAuthClient`;
    try {
      const res = await axiosWithAuth().post(APIURL, {
        // roomName: roomName,
        roomName: "room-4",
        user: user,
        eventData: product.event[0],
      });
      console.log("res", res.data);
      setJwtToken(res.data); // Guardar el JWT correctamente
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button onClick={handleJoinMeeting} disabled={!meetingUrl || !jwtToken}>
        Join Meeting
      </button>
    </div>
  );
}
