//** Import Modules */
import { Space, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { FE_URL, TABLE_DATE_FORMAT } from "../../../../common/constants";
import { getBusinessList } from "../../../../features/reducers/business";
import { useManageCreateBusinessItems } from "../../../../hooks/useManageCreateBusinessItems";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import BusinessVerification from "./BusinessVerification";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { color } from "chart.js/helpers";

export default function MyBusinesses({ user }) {
  const businessList = useSelector((state) => state.business.businessList);

  const navigate = useNavigate();

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    ownerID: user.id,
    dispatchAction: getBusinessList(),
    itemType: "business",
    modalData: {
      title: "Create New Business",
      fieldLabel: "Business Name",
    },
  };

  const [modalComponent, CreateItemButton] =
    useManageCreateBusinessItems(hookData);

  // Generate localstorage to know which business you are managing
  const manageBusiness = (id) => {
    localStorage.setItem("editBusiness", id);

    window.location.replace("/business");
  };

  // Delete business, and dispatch action
  const dispatch = useDispatch();

  const deleteBusiness = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      const APIURL = `/api/business/${id}`;

      axiosWithAuth()
        .delete(APIURL)
        .then((res) => {
          dispatch(getBusinessList());
          console.log(res.data, APIURL);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <>{text.name}</>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(TABLE_DATE_FORMAT)}</>
      ),
    },
    {
      title: "Last Modified",
      key: "modified",
      render: (text) => (
        <>{moment(new Date(text && text.modified)).format(TABLE_DATE_FORMAT)}</>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text) => (
        <>
          {text.activated === 0 && (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Pending Approval
            </Tag>
          )}
          {text.activated === 1 && (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Approved
            </Tag>
          )}
          {text.activated === 2 && (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              Pending changes
            </Tag>
          )}
          {text.activated === 3 && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              Business denied/cancelled
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          {text.activated !== 1 && text.activated !== 3 && (
            <button
              onClick={() => {
                navigate(`${text.id}`);
              }}
            >
              Request Validation
            </button>
          )}

          {text.activated === 1 && text.activated !== 3 && (
            <button
              onClick={() => {
                manageBusiness(`${text.id}`);
              }}
            >
              Manage
            </button>
          )}
          <button
            onClick={() => {
              deleteBusiness(text.id);
            }}
          >
            Delete
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>My Businesses - BIZZLL Dashboard</title>
      </Helmet>

      <Routes>
        <Route
          path="/"
          element={
            <>
              {businessList.length > 0 ? (
                <Table columns={columns} dataSource={businessList} />
              ) : (
                <>
                  <h2>
                    You have no businesses. You may create 1 by clicking the
                    button below, or you can search{" "}
                    <a href={`${FE_URL}/directory`}>our directory</a> to claim
                    your business, if applicable.
                  </h2>
                </>
              )}
              <CreateItemButton btnText="Register Business" />
              {modalComponent}
            </>
          }
          exact
        />

        <Route path="/:id" element={<BusinessVerification />} exact />
      </Routes>
    </>
  );
}
