//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setRestaurantsList } from "../../reducers/restaurants";
import {
  requestGetRestaurantsList,
} from "../requests/restaurants";

export function* handleGetRestaurantsList({ businessID }) {
  try {
    const response = yield call(requestGetRestaurantsList, businessID);
    const { data } = response;

    yield put(setRestaurantsList(data));
  } catch (err) {
    console.log(err.response);
  }
}
