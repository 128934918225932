import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Empty, Space } from "antd";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
// import JitsiMeet from "../routes/BusinessDashboard/pages/JitsiMeet/JitsiMeet";
import JitsiMeet from "../../../../routes/BusinessDashboard/pages/JitsiMeet/JitsiMeet";
import { TABLE_DATE_FORMAT } from "../../../../common/constants";

export default function HomeDailyEvents(props) {
  const { business } = props;

  const [eventsList, setEventsList] = useState([]);
  // const eventsList = useSelector((state) => {
  //   return state.events.eventsList;
  // });

  useEffect(() => {
    getDailyEvents();
  }, [business]);

  const getDailyEvents = async () => {
    try {
      const res = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/event/getAllEventDay/${business.id}`
      );
      console.log("res", res);
    } catch (err) {
      console.error(
        "Error fetching daily events:",
        err.response ? err.response.data : err.message
      );
    }
  };

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <Link to={`${text.id}`}>{text.name}</Link>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <JitsiMeet appointment={text} />
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return (
    <div id="events-page">
      <ListComponent
        emptyMessage="Seems like you don't have an event listing."
        listItems={eventsList}
      />
    </div>
  );
}
