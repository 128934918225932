import React, { useEffect, useState } from "react";
import { Form, Divider, Select, Button, TimePicker, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { TIME_FORMAT } from "../../../../common/constants";
import ConfirmAction from "../../../../utils/ConfirmAction";

const AvailabilityRules = ({ id }) => {
  const [form] = Form.useForm();
  const [service, setService] = useState();
  const [availabilityRules, setAvailabilityRules] = useState({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });
  const [availabilityRulesListDelete, setAvailabilityRulesListDelete] =
    useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const format = "HH:mm";
  const weekOrder = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  useEffect(() => {
    getServiceData();
  }, [id]);

  const getServiceData = async () => {
    try {
      const { data } = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/product/${id}`
      );
      setService({ availabilityRules: data || [] });
    } catch (error) {
      Modal.error({ title: "Error", content: "Failed to fetch service data" });
    }
  };

  useEffect(() => {
    if (service) {
      const currentRules = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };

      const daysWithRules = new Set();

      if (Array.isArray(service.availabilityRules)) {
        service.availabilityRules.forEach((rule) => {
          if (rule.rule_type === "Range of hours") {
            const day = rule.rule_val_1;
            if (currentRules[day]) {
              currentRules[day].push({
                id: rule.id,
                from: rule.rule_val_2,
                to: rule.rule_val_3,
              });
              daysWithRules.add(day);
            }
          }
        });
      }

      setAvailabilityRules(currentRules);
      setSelectedDays(Array.from(daysWithRules));
    }
  }, [service]);

  const handleDayChange = (days) => {
    const sortedDays = days.sort(
      (a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b)
    );
    setSelectedDays(sortedDays);
  };

  const handleAddHourRange = (day) => {
    const updatedRules = { ...availabilityRules };
    const newRange = { id: null, from: "00:00", to: "00:00" };

    updatedRules[day].push(newRange);
    setAvailabilityRules(updatedRules);
  };

  const handleRemoveHourRange = (day, index) => {
    const updatedRules = { ...availabilityRules };
    const removedRule = updatedRules[day][index];
    if (removedRule.id) {
      setAvailabilityRulesListDelete((prev) => [...prev, removedRule]);
    }
    if (updatedRules[day]) {
      updatedRules[day].splice(index, 1);
    }
    setAvailabilityRules(updatedRules);
  };

  const handleTimeChange = (day, index, type, time) => {
    const updatedRules = { ...availabilityRules };
    if (updatedRules[day] && updatedRules[day][index]) {
      const newTime = time.format(format);
      updatedRules[day][index][type] = newTime;
      setAvailabilityRules(updatedRules);
    }
  };

  const isTimeRangeOverlapping = (
    day,
    newRange,
    ranges,
    indexToIgnore = -1
  ) => {
    const newFrom = moment(newRange.from, format);
    const newTo = moment(newRange.to, format);

    return ranges.some((range, index) => {
      if (index === indexToIgnore) return false;
      const from = moment(range.from, format);
      const to = moment(range.to, format);

      return (
        newFrom.isBetween(from, to, undefined, "[)") ||
        newTo.isBetween(from, to, undefined, "(]") ||
        (from.isBetween(newFrom, newTo, undefined, "[)") &&
          to.isBetween(newFrom, newTo, undefined, "(]"))
      );
    });
  };

  const validateAndSubmit = async () => {
    for (const day of selectedDays) {
      const ranges = availabilityRules[day];
      for (let i = 0; i < ranges.length; i++) {
        const range = ranges[i];

        const from = moment(range.from, format);
        const to = moment(range.to, format);

        if (from.isSameOrAfter(to)) {
          Modal.error({
            title: "Validation Error",
            content: `The 'from' time on ${day} must be earlier than the 'to' time.`,
          });
          return;
        }

        if (isTimeRangeOverlapping(day, range, ranges, i)) {
          Modal.error({
            title: "Validation Error",
            content: `The time range on ${day} from ${range.from} to ${range.to} overlaps with another range.`,
          });
          return;
        }
      }
    }

    await handleSubmit();
  };

  const handleSubmit = async () => {
    if (selectedDays.length === 0) {
      Modal.error({
        title: "Validation Error",
        content: "You must select at least one day to provide the service",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const newRules = [];
      const existingRules = [];
      for (const day of selectedDays) {
        for (const range of availabilityRules[day]) {
          const rule = {
            rule_val_1: day,
            rule_val_2: range.from,
            rule_val_3: range.to,
            rule_type: "Range of hours",
            product_id: id,
          };

          if (range.id === null) {
            newRules.push(rule);
          } else {
            rule.id = range.id;
            existingRules.push(rule);
          }
        }
      }

      if (newRules.length > 0) {
        await axiosWithAuth().post(
          `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/create`,
          newRules
        );
      }

      if (existingRules.length > 0) {
        await axiosWithAuth().put(
          `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/update`,
          existingRules
        );
      }

      if (availabilityRulesListDelete.length > 0) {
        await axiosWithAuth().post(
          `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/delete`,
          availabilityRulesListDelete
        );
      }

      Modal.success({
        title: "Success",
        content: "Settings were saved successfully",
      });

      getServiceData();
    } catch (err) {
      Modal.error({
        title: "Error",
        content: `Failed to save availability rules: ${err.message}`,
      });
    } finally {
      setIsSubmitting(false);
      setAvailabilityRulesListDelete([]);
    }
  };

  return (
    <>
      <ContentPageTitle title="Manage Service" icon="pen-to-square" />
      <Divider />
      {service === undefined ? (
        <LoadingForm />
      ) : (
        <div id="services">
          <Form
            form={form}
            layout="vertical"
            initialValues={service}
            onFinish={validateAndSubmit}
            autoComplete="off"
            disabled={isSubmitting}
          >
            <h1>Availability Rules</h1>
            <div id="availability-rules">
              <div className="availability-rules-form">
                <div id="range-of-days" style={{ width: "100%" }}>
                  <h2>Select Days</h2>
                  <Select
                    mode="multiple"
                    showArrow
                    placeholder="Select days"
                    style={{ width: "100%" }}
                    allowClear
                    onChange={handleDayChange}
                    value={selectedDays}
                    options={[
                      { value: "sunday", label: "Sunday" },
                      { value: "monday", label: "Monday" },
                      { value: "tuesday", label: "Tuesday" },
                      { value: "wednesday", label: "Wednesday" },
                      { value: "thursday", label: "Thursday" },
                      { value: "friday", label: "Friday" },
                      { value: "saturday", label: "Saturday" },
                    ]}
                  />
                </div>
              </div>
              <Divider />
              <div className="availability-rules-hours">
                {selectedDays.map((day) => (
                  <div
                    key={day}
                    id={`range-of-hours-${day}`}
                    className="availability-rules_range-of-hours"
                  >
                    <h2>{day.charAt(0).toUpperCase() + day.slice(1)}</h2>
                    {availabilityRules[day].map((range, index) => (
                      <div
                        className="availability-rules-hours-row"
                        key={`${day}-${index}`}
                      >
                        <div className="availability-rules-form">
                          <div className="availability-rules-form_from">
                            <label>From</label>
                            <TimePicker
                              format={TIME_FORMAT}
                              value={moment(range.from, format)}
                              allowClear={false}
                              minuteStep={5}
                              showNow={false}
                              onChange={(time) =>
                                handleTimeChange(day, index, "from", time)
                              }
                            />
                          </div>
                          <div className="availability-rules-form_to">
                            <label>To</label>
                            <TimePicker
                              format={TIME_FORMAT}
                              value={moment(range.to, format)}
                              allowClear={false}
                              minuteStep={5}
                              showNow={false}
                              onChange={(time) =>
                                handleTimeChange(day, index, "to", time)
                              }
                            />
                          </div>
                          <div className="deleteContainer">
                            <ConfirmAction
                              title="Confirm Deletion"
                              content="Are you sure you want to delete this time range?"
                              onConfirm={() =>
                                handleRemoveHourRange(day, index)
                              }
                            >
                              <DeleteOutlined />
                            </ConfirmAction>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Button
                      type="primary"
                      onClick={() => handleAddHourRange(day)}
                    >
                      Add hours
                    </Button>
                  </div>
                ))}
              </div>
            </div>
            <Form.Item style={{ marginTop: "20px" }}>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default AvailabilityRules;
