import React, { useEffect, useState, useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import AnalyticsBoxCounter from "../../../../common/AnalyticsBoxCounter";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import {
  BLUE_COLOR,
  GREEN_COLOR,
  PURPLE_COLOR,
  RED_COLOR,
} from "../../../../common/constants";
import moment from "moment";
import DateRangeChooser from "../../components/DateRangeChooser";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ManageAnalytics({ business }) {
  const dateFormat = "MMM D, YYYY";

  const [visitors, setVisitors] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [address, setAddress] = useState([]);
  const [phone, setPhone] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (business.id) {
      const APIURL = `/api/business/analytics/${business.id}?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;

      axiosWithAuth()
        .get(APIURL)
        .then((res) => {
          const visitorData = res.data.analytics.filter(
            (item) => item.component === "profile_visit"
          );
          const addressData = res.data.analytics.filter(
            (item) => item.component === "address_click"
          );
          const phoneData = res.data.analytics.filter(
            (item) => item.component === "phone_click"
          );

          setVisitors(visitorData);
          setFollowers(res.data.followers);
          setAddress(addressData);
          setPhone(phoneData);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.response ? err.response.data : "Error fetching data");
          setLoading(false);
        });
    }
  }, [business, dateRange]);

  const handleDateRangeChange = (value) => {
    let startDate, endDate;
    switch (value) {
      case "cur-week":
        startDate = moment().startOf("week").format("YYYY-MM-DD");
        endDate = moment().endOf("week").format("YYYY-MM-DD");
        break;
      case "last-week":
        startDate = moment()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "week")
          .endOf("week")
          .format("YYYY-MM-DD");
        break;
      case "cur-month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "last-month":
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    setDateRange({ startDate, endDate });
  };

  const generateLabels = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const labels = [];
    for (
      let date = start;
      date.isBefore(end) || date.isSame(end);
      date.add(1, "days")
    ) {
      labels.push(date.format(dateFormat));
    }
    return labels;
  };

  const labels = useMemo(
    () => generateLabels(dateRange.startDate, dateRange.endDate),
    [dateRange]
  );

  const getDataCount = (data, date) => {
    return data.filter(
      (item) => moment(item.date_recorded).format(dateFormat) === date
    ).length;
  };

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "Visits",
          data: labels.map((date) => getDataCount(visitors, date)),
          borderColor: PURPLE_COLOR,
          backgroundColor: PURPLE_COLOR,
        },
        {
          label: "Followers",
          data: labels.map((date) => getDataCount(followers, date)),
          borderColor: RED_COLOR,
          backgroundColor: RED_COLOR,
        },
        {
          label: "Get Directions Clicks",
          data: labels.map((date) => getDataCount(address, date)),
          borderColor: GREEN_COLOR,
          backgroundColor: GREEN_COLOR,
        },
        {
          label: "Phone Clicks",
          data: labels.map((date) => getDataCount(phone, date)),
          borderColor: BLUE_COLOR,
          backgroundColor: BLUE_COLOR,
        },
      ],
    }),
    [labels, visitors, followers, address, phone]
  );

  return (
    <div>
      <ContentPageTitle title="Business Insights" icon="chart-column" />
      <div className="content-box">
        <div className="analytics-container">
          <DateRangeChooser onChange={handleDateRangeChange} />
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="main-stats">
                <AnalyticsBoxCounter
                  title="Visits"
                  stat={visitors.length}
                  color="purple"
                />
                <AnalyticsBoxCounter
                  title="Followers"
                  stat={followers.length}
                  color="red"
                />
                <AnalyticsBoxCounter
                  title="Directions Clicks"
                  stat={address.length}
                  color="green"
                />
                <AnalyticsBoxCounter
                  title="Phone Clicks"
                  stat={phone.length}
                  color="blue"
                />
              </div>
              <div className="graph">
                <Line data={data} height={80} />
              </div>

              {/* <div className="demographics">
                  <h3>Demographics</h3>

                  <AgeGroups />
                  <GenderGroups />
                </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
