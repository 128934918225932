import React, { useEffect, useState } from "react";
import { Calendar, Divider } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import moment from "moment";
import AppointmentsByDayTable from "./AppointmentsByDayTable";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function AppointmentsByDay(props) {
  const { business } = props;

  const [listItems, setListItems] = useState([]);
  const [appointmentByDay, setAppointmentByDay] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    getAppointmentsByMonth();
  }, [selectedDate, business]);

  const getAppointmentsByMonth = () => {
    const year = selectedDate.year();
    const month = monthNames[selectedDate.month()];
    const business_id = business.id;

    const APIURL = `/api/appointments/list/monthly`;

    const payload = {
      year,
      month,
      business_id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        setListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const onPanelChange = (value) => {
    console.log(value.format("YYYY-MM-DD"));
    setSelectedDate(value);
  };

  const onSelect = (value) => {
    const selectedDate = value.date();
    const filteredAppointments = listItems.filter(
      (appointment) => moment(appointment.start_time).date() === selectedDate
    );
    setAppointmentByDay(filteredAppointments);
  };

  return (
    <div className="calendar_table_container">
      <div className="calendar_container">
        <Calendar
          fullscreen={false}
          onPanelChange={onPanelChange}
          onSelect={onSelect}
        />
      </div>
      <Divider />
      <div className="appointment_table_container">
        <AppointmentsByDayTable
          appointmentByDay={appointmentByDay}
          handleRefresh={getAppointmentsByMonth}
        />
      </div>
    </div>
  );
}
