//** Import Modules */
import React, { useEffect, useState } from "react";
import { Badge, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBell } from "@fortawesome/free-solid-svg-icons";
import HeaderUserMenu from "./HeaderUserMenu";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "../../features/reducers/notifications";

//** Import Assets */
import logo from "../../images/bizzll-logo-beta.png";
import UserAvatar from "../UserAvatar";
import NotificationItem from "./NotificationItem";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function HeaderUserBar() {
  // Get User Info
  const user = useSelector((state) => state.user.user);
  const [unseenMessages, setUnseenMessages] = useState(0);

  let navigate = useNavigate();

  // Get notifications
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const newNotificationsCount = notifications.filter(
    (item) => item.is_new === 1
  ).length;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    getUnseenMessages();
  }, []);

  const getUnseenMessages = async () => {
    try {
      const data = {
        type: "user",
      };
      const response = await axiosWithAuth().post(
        `/api/messages/getunseen/${user.id}`,
        data
      );
      console.log("response BAR", response);
      setUnseenMessages(response.data.number_unseen_messages);
      // console.log(
      //   "response 2",
      //   response.data.number_unseen_messages.find(
      //     (meta) => meta.meta_key === "unseen_messages"
      //   ).meta_value
      // );
    } catch (error) {
      console.error("Error creating chat", error);
    }
  };

  // Function to set notifications to read
  const notificationsOpen = () => {
    if (newNotificationsCount > 0) {
      const APIURL = `/api/users/user/notifications/open`;

      axiosWithAuth()
        .put(APIURL)
        .then((res) => {
          console.log(res);
          dispatch(getNotifications());
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  return (
    <div id="header-user-bar">
      <Popover
        title="Dashboard Menu"
        trigger="click"
        content={<HeaderUserMenu user={user} />}
        placement="bottomRight"
      >
        <button className="user-avatar">
          <UserAvatar user={user} size={40} single={true} />
        </button>
      </Popover>

      {/* <Popover
        title="Messages"
        trigger="click"
        content={<>Messages</>}
        placement="bottomRight"
        overlayClassName="header-notifications-box"
      > */}
      <button className="userbar-btns messages-btn">
        <Badge count={unseenMessages} overflowCount={10} size="small">
          <FontAwesomeIcon icon={faEnvelope} />
        </Badge>
      </button>
      {/* </Popover> */}

      {/* <button className="userbar-btns messages-btn" onClick={() => {
        navigate(`/account/my-chats`)
      }}>
        <FontAwesomeIcon icon={faEnvelope} />
      </button> */}

      <Popover
        title="Notifications"
        trigger="click"
        overlayClassName="header-notifications-box"
        content={notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
        placement="bottomRight"
      >
        <button
          className="userbar-btns notifications-btn"
          onClick={notificationsOpen}
        >
          <Badge count={newNotificationsCount} overflowCount={10} size="small">
            <FontAwesomeIcon icon={faBell} />
          </Badge>
        </button>
      </Popover>

      <div className="vertical-divider"></div>

      {/* <button className="extra-buttons upgrade-btn">Upgrade</button> */}
      <a href={`${process.env.REACT_APP_FE_URL}`} className="back-to-bizzll">
        <img src={logo} alt="BIZZLL Logo" />
      </a>
    </div>
  );
}
