//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setServicesList } from "../../reducers/services";
import {
  requestGetServicesList,
} from "../requests/services";

export function* handleGetServicesList({ businessID }) {
  try {
    const response = yield call(requestGetServicesList, businessID);
    const { data } = response;

    yield put(setServicesList(data));
  } catch (err) {
    console.log(err.response);
  }
}
