//** Import Modules */
import { useState } from "react";
import axiosGeneral from "../utils/axioGenetal";
import { Alert, Modal, Button } from "antd";
import { setCookie } from "../common/manageCookies";

export const useLoginUser = () => {
  const [warningModal, setWarningModal] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [response, setResponse] = useState("");

  const logUserIn = (values) => {
    setIsSubmitting(true);
    setResponse("");
    const APIURL = "/api/users/login";

    const userData = {
      user_login: values.username,
      user_pass: values.password,
    };

    axiosGeneral()
      .post(APIURL, userData)
      .then((res) => {
        console.log(res.data);

        const tokenInfo = {
          token: res.data.token,
        };

        setCookie("ubt", tokenInfo.token, 1);

        setTimeout(() => {
          setResponse(<Alert message={res.data.message} type="success" />);

          const URLQueryVars = new URL(document.location).searchParams;
          const redirect = URLQueryVars.get("redirect");

          if (redirect && redirect !== "") {
            window.location.href = `${redirect}`;
          } else {
            window.location.href = "/account/";
          }
        }, 500);
      })
      .catch((err) => {
        console.log(err.response);

        setTimeout(() => {
          setIsSubmitting(false);
          setResponse(
            <Alert message={err.response.data.message} type="error" />
          );
        }, 500);
      });
  };

  //* Function to refresh login token
  const refreshToken = () => {
    console.log("Token refresh");
    setIsSubmitting(true);
  };

  //* Function to open the modal
  const refreshTokenWarning = () => {
    setWarningModal(true);
  };

  const sessionModalComponent = (
    <Modal
      title="Title"
      open={warningModal}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          onClick={refreshToken}
        >
          Refresh Session
        </Button>,
      ]}
    >
      <p>Your session is about to expire</p>
    </Modal>
  );

  return [
    logUserIn,
    isSubmitting,
    response,
    refreshTokenWarning,
    sessionModalComponent,
  ];
};
