import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useManagementEventsUser } from "../../../hooks/useManagementEventsUser";
// import { AppointmentsUserSettings } from "./AppointmentsUserSettings";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { Alert } from "antd";

export default function ManageEventsUser(props) {
  const { user } = props;

  const [listItems, SetListItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      axiosWithAuth()
        .get(
          `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/getAllOnlinePurchasedTickets`
        )
        .then((res) => {
          SetListItems(res.data);
        });
    } catch (err) {
      console.log(err.response);
    }
  }, [user.id, dispatch]);

  // Custom hook to manage the creation/deletion of user listing
  const hookData = {
    creatorID: user.id,
    user: user,
    itemType: "service",
    type: "service",
  };

  const [ListComponent] = useManagementEventsUser(hookData);

  return (
    <div id="orders-page">
      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an event listing."
                  listItems={listItems}
                />
              </>
            }
            exact
          />

          {/* <Route path="/:id" element={<AppointmentsUserSettings />} exact /> */}
        </Routes>
      </div>
    </div>
  );
}
