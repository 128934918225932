//** Import modules */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification } from "antd";
import { useContext, useEffect } from "react";

//** Import Contexts */
import { SocketContext } from "../context/socket";
import { useSelector } from "react-redux";

//** Import Libraries */
library.add(fas);

export default function NotificationManager() {
  //* Bring the user info and socket
  const user = useSelector((state) => state.user.user);
  const socket = useContext(SocketContext);

  //* Function to open the notifications box
  const openNotification = (data) => {
    const args = {
      message: data.message,
      description: data.description,
      icon: <FontAwesomeIcon icon={data.icon !== "" ? data.icon : "bell"} />,
      className: data.className,
    };

    notification.open({
      ...args,
      placement: "bottomLeft",
      duration: 0,
    });
  };

  useEffect(() => {
    socket.on("receive-notification", (userID, data, icon) => {
      if (user.id === userID && data.className === "follower") {
        openNotification(data);
      }
    });
  }, [user.id, socket]);

  return <div></div>;
}
