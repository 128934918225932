import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { getMessageThreads } from "../../../../features/reducers/messages";
import { useDispatch, useSelector } from "react-redux";
import Chats from "../../../UserDashboard/pages/Chat/Chats";

export default function PrivateMessages(props) {
  const { business } = props;

  const user = useSelector((state) => state.user.user);

  return (
    <div>
      <ContentPageTitle title="Messages" icon="users" />

      <div className="content-box">
        <Chats user={user} type="business" idBusiness={business.id} />
      </div>
    </div>
  );
}
