import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { Button, Divider, message, Form, Input } from "antd";
import ListBackBtn from "../../../BusinessDashboard/components/ListBackBtn";
import ConfirmAction from "../../../../utils/ConfirmAction";

export default function BusinessSettings(props) {
  const { handleGetBusinessWaitingValidation } = props;

  const { id: businessID } = useParams();
  const [business, setBusiness] = useState(null);
  const [metaData, setMetaData] = useState({});
  const [userWhoApproved, setUserWhoApproved] = useState();

  const user = useSelector((state) => state.user.user);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetBusiness();
  }, [businessID]);

  const handleGetBusiness = async () => {
    try {
      const response = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/business/${businessID}`
      );
      setBusiness(response.data);
      setUserWhoApproved(response.data.user_who_approved_name);
      createMetaDataObj(response.data.meta_data);
    } catch (error) {
      const config = {
        content: error.response?.data?.message || "ERROR LOADING BUSINESS DATA",
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
    }
  };

  const onFinish = async (values, activatedValue) => {
    const data = {
      activated: activatedValue,
      meta_data: [
        {
          meta_key: "user_who_approved",
          meta_value: user.id,
        },
        {
          meta_key: "approval_comment",
          meta_value: values.meta_approval_comment,
        },
      ],
    };
    try {
      await axiosWithAuth().put(
        `${process.env.REACT_APP_BE_API_URL}/api/business/${business.id}`,
        data
      );
      navigate(`/bizzll-management/business-management`);
      handleGetBusinessWaitingValidation();
      const config = {
        content:
          activatedValue === 1
            ? "Business Approved Successfully"
            : "Request Processed Successfully",
        style: {
          background: "none",
        },
      };
      message.success(config);
    } catch (error) {
      const config = {
        content: error.response?.data?.message || "Generic error occurred",
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
    }
  };

  const createMetaDataObj = (arr) => {
    let data = {};
    arr.forEach((element) => {
      const meta_key = element.meta_key;
      const meta_value = element.meta_value;
      data[meta_key] = meta_value;
    });
    setMetaData(data);
  };

  const handleApprove = () => {
    form
      .validateFields()
      .then((values) => onFinish(values, 1))
      .catch((errorInfo) => console.log(errorInfo));
  };

  const handleRequestChanges = () => {
    form
      .validateFields()
      .then((values) => onFinish(values, 2))
      .catch((errorInfo) => console.log(errorInfo));
  };

  const handleDeny = () => {
    form
      .validateFields()
      .then((values) => onFinish(values, 3))
      .catch((errorInfo) => console.log(errorInfo));
  };

  return (
    <>
      <ListBackBtn
        backURL="/bizzll-management/business-management"
        btnTxt="Back To Inactive Businesses List"
      />
      {business && (
        <>
          <h3>Business Name: {business.name}</h3>
          <h3>Business Phone Number: {metaData.business_phone}</h3>
          <h3>Business Email: {metaData.business_email}</h3>
          <h3>Business FEI/EIN Number: {metaData.business_fei_ein_number}</h3>
          <h3>
            Business Address:{" "}
            {`
              ${metaData.business_address},
              ${metaData.business_address_2 || ""},
              ${metaData.business_city},
              ${metaData.business_state},
              ${metaData.business_country},
              ${metaData.business_zip}
            `}
          </h3>
          <h3>Agent Name: {metaData.business_agent_name}</h3>
          <h3>Agent Title: {metaData.business_agent_title}</h3>
          <h3>Agent Address: {metaData.agent_address}</h3>

          {metaData.user_who_approved && (
            <>
              <Divider />
              {console.log("metaData", metaData)}
              {/* <h3>User who approved: {metaData.user_who_approved}</h3> */}
              {userWhoApproved && (
                <h3>User who approved Name: {userWhoApproved}</h3>
              )}
              <h3>Comment: {metaData.approval_comment}</h3>
            </>
          )}
        </>
      )}

      <Divider />

      <Form form={form} autoComplete="off">
        <Form.Item
          label="Add/Edit Comment"
          name="meta_approval_comment"
          tooltip="Bizzll require leaving a comment about the changes made."
        >
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            className="formDescription"
            placeholder="Comment..."
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {business !== null && business.activated !== 1 && (
            <Form.Item>
              <Button
                type="primary"
                onClick={handleApprove}
                style={{
                  marginRight: 15,
                }}
              >
                Approve
              </Button>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type="primary"
              onClick={handleRequestChanges}
              style={{
                marginRight: 15,
              }}
            >
              Request Changes
            </Button>
          </Form.Item>
          <Form.Item>
            <ConfirmAction
              title="Confirm Deny"
              content="Are you sure you want to continue?"
              onConfirm={handleDeny}
            >
              <Button
                type="primary"
                style={{
                  marginRight: 15,
                }}
              >
                Cancel/Deny
              </Button>
            </ConfirmAction>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
