import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import axiosGeneral from "../../utils/axioGenetal";
import { validatePassword } from "../../utils/validations";

export default function ResetPasswordPage() {
  //* Set states
  const [isSubmitting, setisSubmitting] = useState(false);
  const [response, setResponse] = useState("");
  const [isValid, setIsValid] = useState(false);

  //* Get the token
  const { token } = useParams();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //* Verify token
  useEffect(() => {
    const APIURL = `${process.env.REACT_APP_BE_API_URL}/api/users/resetPassword/verify`;

    axiosGeneral()
      .post(APIURL, { verificationToken: token })
      .then((res) => {
        console.log(res);
        setResponse("");

        setIsValid(res.data.verify);
      })
      .catch((err) => {
        console.log(err.response);
        setResponse(err.response.data.message);
        setIsValid(err.response.data.verify);
      });
  }, [token]);

  //* Rese Password
  const resetPassword = (values) => {
    setisSubmitting(true);

    const APIURL = `${process.env.REACT_APP_BE_API_URL}/api/users/resetPassword`;

    const sendData = {
      user_pass: values.user_pass,
      token: token,
    };

    axiosGeneral()
      .post(APIURL, sendData)
      .then((res) => {
        setisSubmitting(false);
        setResponse(res.data.message);

        setTimeout(() => {
          window.location.href = "/?pr=yes";
        }, 1000);
      })
      .catch((err) => {
        console.log(err.response);
        setisSubmitting(false);
        setResponse(err.response.data.message);
      });
  };

  return (
    <div className="message-page">
      <Helmet>
        <title>Reset Password - BIZZLL Dashboard</title>
      </Helmet>

      <div className="message-box">
        <h2>Reset Password</h2>

        <div className="inner-container login-forms">
          {isValid && (
            <Form
              layout="vertical"
              onFinish={resetPassword}
              onFinishFailed={onFinishFailed}
              disabled={isSubmitting}
              autoComplete="off"
            >
              <Form.Item
                label="New Password"
                name="user_pass"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  { validator: validatePassword }
                ]}
              >
                <Input.Password disabled={isSubmitting} />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["user_pass"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("user_pass") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password disabled={isSubmitting} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          )}

          {response !== "" && response}
        </div>
      </div>
    </div>
  );
}
