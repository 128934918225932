//** Define Actions */
export const GET_SERVICES_LIST = "GET_SERVICES_LIST";
export const SET_SERVICES_LIST = "SET_SERVICES_LIST";

//** Create action functions */
export const getServicesList = (businessID) => ({
  type: GET_SERVICES_LIST,
  businessID,
});

export const setServicesList = (servicesList) => ({
  type: SET_SERVICES_LIST,
  servicesList,
});

//** Create initial state */
const initialState = {
  servicesList: [],
};

//** Create the handler function */
const servicesReducer = (state = initialState, { type, service, servicesList }) => {
  switch (type) {
    case SET_SERVICES_LIST:
      return {
        ...state,
        servicesList: servicesList.map((service) => {
          return {
            ...service,
            key: service.id,
          };
        }),
      };
    default:
      return state;
  }
};

export default servicesReducer;
